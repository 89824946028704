@charset "UTF-8";
/*
Theme Name: Bausins
Theme URI:
Author:
Author URI:
Description: Bausins is a business website template
Version: 1.0.0
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------

# Typography
# Normalize
# Accessibility
# Globals
# Alignments
# Clearings
# Posts and pages
# Captions
# Galleries
# Unit test
# guterberg
# Gutenberg	Default Style
# Block Color Palette Colors
# Sections
    ## Breadcrumb
    ## Comments
    ## Widgets
    ## Nav Bar
    ## Top Bar
    ## Info Bar
    ## Header
    ## Header Bottom Area
    ## Call To Action
    ## Our Work Area
    ## Testimonial Area
    ## Footer Area
    ## Abous Us Area
    ## Our Cover Area
    ## Counterup Area
    ## Who We Are Area
    ## Product Area
    ## Error 404 Area

# Elements
    ## Back Top
    ## Preloader
    ## Search Popup
    ## Video Popup
    ## Button
    ## Section Title
    ## Icon Box One
    ## Icon Box Two
    ## Icon Box Three
    ## Work Item
    ## Team
    ## Testimonial
    ## Blog Grid Item
    ## Featured Box One
    ## Counterup Item
    ## Request Call Form
    ## Accordion Item
    ## Experience Item
    ## Service Item
    ## Portfolio Item
    ## Product Item
    ## Contact Form Item
    ## Contact Info Item

--------------------------------------------------------------*/
/*-----------------
    # Typography
-----------------*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&amp;display=swap");

:root {
    --primary-color: #D92B9C;
    --secondary-color: #2E3C8C;
    --heading-color: #404040;
    --paragraph-color: #666;
    --body-font: 'Montserrat', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-x: hidden;
    font-family: var(--body-font);
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */
}

body {
    margin: 0;
    color: #404040;
    overflow-x: hidden;
    font-family: var(--body-font);
}

.grey-bg {
    background: #F9F9FA !important;
}

.white-bg {
    background: #fff !important;
}

.black-bg {
    background: #404040 !important;
}

.main-bg {
    background: var(--secondary-color) !important;
}

.color-black {
    color: var(--heading-color) !important;
}

.color-main {
    color: var(--primary-color) !important;
}

.color-white {
    color: #fff !important;
}

.color-red {
    color: #FA2727 !important;
}

.color-lgray {
    color: #D1D1D1 !important;
}

.font-bold {
    font-weight: 700;
}

.font-semibold {
    font-weight: 600;
}

.font-regular {
    font-weight: 400;
}

.font-24 {
    font-size: 24px !important;
}

.section-padding {
    padding-top: 140px;
    padding-bottom: 140px;
}

h1 {
    font-size: 80px;
}

h2 {
    font-size: 55px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
    font-family: var(--body-font);
}

p {
    color: var(--paragraph-color);
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    margin-bottom: 10px;
    font-size: 16px;
}

a {
    color: inherit;
    text-decoration: none;
    color: #404040;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: inherit;
}

pre {
    word-break: break-word;
}

a i {
    padding: 0 2px;
}

img {
    max-width: 100%;
}

ol {
    counter-reset: counter;
    padding-left: 0;
}

ol li {
    list-style: none;
    margin-bottom: 1rem;
}

ol li:before {
    counter-increment: counter;
    content: counter(counter);
    font-weight: 500;
    margin-right: 10px;
}

/*input and button type focus outline disable*/
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ddd;
}

.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.no-gutter > [class^="col-"] {
    padding-left: 0;
    padding-right: 0;
}

.no-gutter[class^="col-"] {
    padding-left: 0;
    padding-right: 0;
}

.h-100vh {
    height: 100vh;
}

code {
    color: #faa603;
}

.section-bg-1 {
    background-color: #f4f7fc;
}

.check-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.check-list li {
    display: block;
    padding-left: 20px;
    position: relative;
    z-index: 0;
}

.check-list li:after {
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    content: "\f105";
    color: var(--primary-color);
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
    clear: both;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
    float: left;
    width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
    float: right;
    text-align: right;
    width: 50%;
}

.comment-navigation .nav-previous > a,
.posts-navigation .nav-previous > a,
.post-navigation .nav-previous > a,
.comment-navigation .nav-next > a,
.posts-navigation .nav-next > a,
.post-navigation .nav-next > a {
    -webkit-transition: .3s ease-in;
    -o-transition: .3s ease-in;
    transition: .3s ease-in;
}

.comment-navigation .nav-previous:hover > a,
.posts-navigation .nav-previous:hover > a,
.post-navigation .nav-previous:hover > a,
.comment-navigation .nav-next:hover > a,
.posts-navigation .nav-next:hover > a,
.post-navigation .nav-next:hover > a {
    color: var(--primary-color);
}

.comment-list li {
    list-style: none;
}

.h-100vh {
    height: 100vh;
}

.position-relative {
    position: relative;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
    /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    -webkit-clip-path: none;
    clip-path: none;
    color: #21759b;
    display: block;
    font-size: 15px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
    outline: 0;
}

/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/
.small {
    &-pt {
        padding-top: 50px;

        @include media-breakpoint-up(md) {
            padding-top: 80px;
        }
    }

    &-pb {
        padding-bottom: 50px;

        @include media-breakpoint-up(md) {
            padding-bottom: 80px;
        }
    }
}

.padding-left-0 {
    padding-left: 0;
}

.padding-right-0 {
    padding-left: 0;
}

.gray-bg {
    background-color: #f8f8f8;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-30 {
    padding-top: 30px;
}

.padding-top-33 {
    padding-top: 33px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-70 {
    padding-top: 70px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-85 {
    padding-top: 85px;
}

.padding-top-90 {
    padding-top: 90px;
}

.padding-top-95 {
    padding-top: 95px;
}

.padding-top-100 {
    padding-top: 100px;
}

.padding-top-105 {
    padding-top: 105px;
}

.padding-top-110 {
    padding-top: 110px;
}

.padding-top-115 {
    padding-top: 115px;
}

.padding-top-120 {
    padding-top: 120px;
}

.padding-top-125 {
    padding-top: 125px;
}

.padding-top-130 {
    padding-top: 130px;
}

.padding-top-135 {
    padding-top: 135px;
}

.padding-top-140 {
    padding-top: 140px;
}

.padding-top-145 {
    padding-top: 145px;
}

.padding-top-150 {
    padding-top: 150px;
}

.padding-top-160 {
    padding-top: 160px;
}

.padding-top-165 {
    padding-top: 165px;
}

.padding-top-162 {
    padding-top: 162px;
}

.padding-top-170 {
    padding-top: 170px;
}

.padding-top-175 {
    padding-top: 175px;
}

.padding-top-180 {
    padding-top: 180px;
}

.padding-top-185 {
    padding-top: 185px;
}

.padding-top-190 {
    padding-top: 190px;
}

.padding-top-195 {
    padding-top: 195px;
}

.padding-top-200 {
    padding-top: 200px;
}

.padding-top-225 {
    padding-top: 225px;
}

.padding-top-230 {
    padding-top: 230px;
}

.padding-top-238 {
    padding-top: 238px;
}

.padding-top-240 {
    padding-top: 240px;
}

.padding-top-255 {
    padding-top: 255px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-55 {
    padding-bottom: 55px;
}

.padding-bottom-58 {
    padding-bottom: 58px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-63 {
    padding-bottom: 63px;
}

.padding-bottom-65 {
    padding-bottom: 65px;
}

.padding-bottom-70 {
    padding-bottom: 70px;
}

.padding-bottom-75 {
    padding-bottom: 75px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-85 {
    padding-bottom: 85px;
}

.padding-bottom-90 {
    padding-bottom: 90px;
}

.padding-bottom-95 {
    padding-bottom: 95px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}

.padding-bottom-110 {
    padding-bottom: 110px;
}

.padding-bottom-105 {
    padding-bottom: 105px;
}

.padding-bottom-115 {
    padding-bottom: 115px;
}

.padding-bottom-120 {
    padding-bottom: 120px;
}

.padding-bottom-130 {
    padding-bottom: 130px;
}

.padding-bottom-135 {
    padding-bottom: 135px;
}

.padding-bottom-140 {
    padding-bottom: 140px;
}

.padding-bottom-145 {
    padding-bottom: 145px;
}

.padding-bottom-150 {
    padding-bottom: 150px;
}

.padding-bottom-170 {
    padding-bottom: 170px;
}

.padding-bottom-175 {
    padding-bottom: 175px;
}

.padding-bottom-180 {
    padding-bottom: 180px;
}

.padding-bottom-185 {
    padding-bottom: 185px;
}

.padding-bottom-190 {
    padding-bottom: 190px;
}

.padding-bottom-195 {
    padding-bottom: 195px;
}

.padding-bottom-200 {
    padding-bottom: 200px;
}

.padding-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.padding-110 {
    padding-top: 110px;
    padding-bottom: 110px;
}

.padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.padding-85 {
    padding-top: 85px;
    padding-bottom: 85px;
}

.padding-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.padding-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-45 {
    padding-top: 45px;
    padding-bottom: 45px;
}

.padding-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.padding-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.padding-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-lr-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-45 {
    margin-top: 45px;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.margin-top-55 {
    margin-top: 55px !important;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-65 {
    margin-top: 65px;
}

.margin-top-70 {
    margin-top: 70px;
}

.margin-top-75 {
    margin-top: 75px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-85 {
    margin-top: 85px !important;
}

.margin-top-90 {
    margin-top: 90px !important;
}

.margin-top-100 {
    margin-top: 100px;
}

.margin-top-105 {
    margin-top: 105px !important;
}

.margin-top-110 {
    margin-top: 110px !important;
}

.margin-top-115 {
    margin-top: 115px !important;
}

.margin-top-125 {
    margin-top: 125px !important;
}

.margin-top-120 {
    margin-top: 120px !important;
}

.margin-top-130 {
    margin-top: 130px !important;
}

.margin-top-135 {
    margin-top: 135px !important;
}

.margin-top-140 {
    margin-top: 140px !important;
}

.margin-top-150 {
    margin-top: 150px;
}

.margin-top-165 {
    margin-top: 165px !important;
}

.margin-top-170 {
    margin-top: 170px !important;
}

.margin-top-205 {
    margin-top: 205px !important;
}

.margin-top-200 {
    margin-top: 200px !important;
}

.margin-top-210 {
    margin-top: 210px !important;
}

.margin-top-215 {
    margin-top: 215px !important;
}

.margin-top-220 {
    margin-top: 220px !important;
}

.margin-top-225 {
    margin-top: 225px !important;
}

.margin-top-230 {
    margin-top: 230px !important;
}

.margin-top-270 {
    margin-top: 270px !important;
}

.margin-top-300 {
    margin-top: 300px !important;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-bottom-6 {
    margin-bottom: 6px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-45 {
    margin-bottom: 45px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-55 {
    margin-bottom: 55px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-65 {
    margin-bottom: 65px;
}

.margin-bottom-70 {
    margin-bottom: 70px;
}

.margin-bottom-75 {
    margin-bottom: 75px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-90 {
    margin-bottom: 90px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}

.margin-bottom-110 {
    margin-bottom: 110px;
}

.margin-bottom-115 {
    margin-bottom: 115px;
}

.margin-bottom-120 {
    margin-bottom: 120px;
}

.margin-bottom-125 {
    margin-bottom: 125px !important;
}

.margin-bottom-140 {
    margin-bottom: 140px !important;
}

.margin-bottom-130 {
    margin-bottom: 130px !important;
}

.margin-bottom-135 {
    margin-bottom: 135px;
}

.margin-bottom-150 {
    margin-bottom: 150px;
}

.margin-bottom-165 {
    margin-bottom: 165px;
}

.margin-bottom-180 {
    margin-bottom: 180px;
}

.margin-bottom-175 {
    margin-bottom: 175px;
}

.margin-bottom-200 {
    margin-bottom: 200px !important;
}

.margin-bottom-225 {
    margin-bottom: 225px !important;
}

.margin-bottom-230 {
    margin-bottom: 230px !important;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
    content: "";
    display: table;
    table-layout: fixed;
}

.clear:after,
.site-header:after,
.site-content:after,
.site-footer:after {
    clear: both;
}

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
    display: block;
}

.updated:not(.published) {
    display: none;
}

.pagination {
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        display: inline-block;
        margin-right: .5rem;

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        display: block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: 600;
        -webkit-transition: .3s ease-in;
        -o-transition: .3s ease-in;
        transition: .3s ease-in;
        color: #BCBCBC;
        border-radius: 50%;
        background: transparent;
        border: 1px solid #BCBCBC;
        cursor: pointer;

        &.current,
        &:not(&--disabled):hover {
            background-color: var(--secondary-color);
            color: #fff;
        }
    }

    &__info {
        display: block;
        width: auto;
        height: 50px;
        padding: 0 1rem;
        line-height: 50px;
        text-align: center;
        font-weight: 600;
        color: #BCBCBC;
        background: transparent;
        border: none;
    }

    &__current-page {
        padding: 0.5rem 1rem;
        background-color: #DEDEDE;
        color: #7D7D7D;
        margin-right: 0.5rem;
    }
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
    max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
    display: inline-block;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
    margin-bottom: 1.5em;
}

.gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
}

.gallery-columns-2 .gallery-item {
    max-width: 50%;
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
    max-width: 25%;
}

.gallery-columns-5 .gallery-item {
    max-width: 20%;
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}

.gallery-caption {
    display: block;
}

/*----------------------------------------
    # Unit test
------------------------------------------*/
dl,
ol,
ul {
    padding-left: 15px;
}

.post-password-form input {
    display: block;
    border: 1px solid #e2e2e2;
    height: 50px;
    border-radius: 3px;
    padding: 0 20px;
}

.post-password-form label {
    font-weight: 600;
    color: #333;
}

.post-password-form input[type=submit] {
    width: 100px;
    height: 50px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
    -webkit-transition: .3s ease-in;
    -o-transition: .3s ease-in;
    transition: .3s ease-in;
}

.post-password-form input[type=submit]:hover {
    background-color: #121A2F;
}

.footer-widget .table td,
.footer-widget .table th {
    padding: 0.50rem !important;
}

/*--------------------------------------------------------------
# Gutenberg	Default Style
--------------------------------------------------------------*/
.single-post-details-item .entry-content > .alignwide {
    max-width: 1100px;
}

.single-post-details-item .entry-content > .alignfull {
    margin: 1.5em 0;
    max-width: 100%;
}

/*--------------------------------------------------------------
## Block Color Palette Colors
--------------------------------------------------------------*/
.has-strong-blue-color {
    color: #0073aa;
}

.has-strong-blue-background-color {
    background-color: #0073aa;
}

.has-lighter-blue-color {
    color: #229fd8;
}

.has-lighter-blue-background-color {
    background-color: #229fd8;
}

.has-very-light-gray-color {
    color: #eee;
}

.has-very-light-gray-background-color {
    background-color: #eee;
}

.has-very-dark-gray-color {
    color: #444;
}

.has-very-dark-gray-background-color {
    background-color: #444;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1280px;
    }
}

.item-bounce {
    -webkit-animation: bounce 3s infinite ease-in-out;
    -o-animation: bounce 3s infinite ease-in-out;
    -ms-animation: bounce 3s infinite ease-in-out;
    -moz-animation: bounce 3s infinite ease-in-out;
    animation: bounce 3s infinite ease-in-out;
}

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    50% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
    100% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@keyframes bounce {
    0% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    50% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
    100% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

/* Breadcrumb */
.breadcrumb-area {
    height: 250px;
    display: flex;
    align-items: center;
    padding-top: 93.5px;

    @include media-breakpoint-up(md) {
        height: 300px;
        padding-top: 106px;
    }
}

.breadcrumb-area .breadcrumb-inner .page-title {
    color: #fff;
    font-family: var(--body-font);
    font-size: 2.75rem;
    font-weight: 700;
}

.breadcrumb-area .breadcrumb-inner .page-list {
    margin: 0;
    padding: 0;
}

.breadcrumb-area .breadcrumb-inner .page-list li {
    list-style: none;
    float: left;
    margin-right: 10px;
}

.breadcrumb-area .breadcrumb-inner .page-list li a {
    color: #fff;
    font-size: 16px;
    font-family: var(--secondary-font);
    opacity: .6;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.breadcrumb-area .breadcrumb-inner .page-list li a:hover {
    opacity: 1;
}

/*--------------------------------------------------------------
	## Widgets
--------------------------------------------------------------*/
.widget {
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: #f4f4f4;

    &:last-child {
        margin-bottom: 0;
    }

    &__title {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--secondary-color);
    }

    &__body {

    }
}

/*.widget select {
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    font-weight: 500;
}

.widget .widget-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 10px;
    position: relative;
    letter-spacing: 0px;
    font-family: var(--body-font);
}

.widget.widget_calendar {
    padding-right: 24px;
    padding-left: 24px;
}*/

.widget.widget_nav_menu ul li.menu-item-has-children:after {
    display: none;
}

.footer-widget .subscribe-form {
    background: #fff;
    border-radius: 5px;
}

.footer-widget .subscribe-form .input-group .form-control {
    padding: 12px 15px;
    font-size: 15px;
    border: 0px;
}

.footer-widget .subscribe-form .input-group .form-control::-webkit-input-placeholder {
    /* WebKit browsers */
    color: rgba(64, 64, 64, 0.7);
}

.footer-widget .subscribe-form .input-group .form-control:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(64, 64, 64, 0.7);
}

.footer-widget .subscribe-form .input-group .form-control::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(64, 64, 64, 0.7);
}

.footer-widget .subscribe-form .input-group .form-control:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgba(64, 64, 64, 0.7);
}

.footer-widget .subscribe-form .input-group .form-control:focus {
    border-color: var(--secondary-color);
}

.footer-widget .subscribe-form .submit-btn {
    padding: 2px 18px;
    background: #B22234;
    margin-right: 6px;
    margin-top: 5px;
}

.footer-widget .subscribe-form .submit-btn:hover {
    background-color: var(--secondary-color) !important;
    color: #fff !important;
}

.footer-widget .subscribe-form.subscribe-form-style2 .form-control {
    background: transparent;
    border-color: var(--secondary-color);
    padding: 20px 20px;
    border-radius: 20px 0px 0px 20px;
}

.footer-widget .subscribe-form.subscribe-form-style2 .form-control::-webkit-input-placeholder {
    /* WebKit browsers */
    color: rgba(255, 255, 255, 0.7);
}

.footer-widget .subscribe-form.subscribe-form-style2 .form-control:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(255, 255, 255, 0.7);
}

.footer-widget .subscribe-form.subscribe-form-style2 .form-control::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(255, 255, 255, 0.7);
}

.footer-widget .subscribe-form.subscribe-form-style2 .form-control:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgba(255, 255, 255, 0.7);
}

.footer-widget .subscribe-form.subscribe-form-style2 .btn {
    border-radius: 0px 20px 20px 0px;
    padding: 20px 45px;
    font-size: 15px;
    letter-spacing: 0.4px;
}

.footer-widget {
    padding: 0;
    margin-bottom: 45px;
}

.widget ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.widget ul li ul {
    margin-left: 20px;
}

.widget ul li {
    display: block;
    word-break: break-word;
}

.widget ul li a {
    color: inherit;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.widget ul li a:hover {
    color: var(--primary-color);
}

.footer-widget.widget_nav_menu ul li a {
    position: relative;
    padding-left: 15px;
}

.footer-widget.widget_nav_menu ul li a:after {
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "";
    color: var(--primary-color);
}

.widget_search .search-form {
    position: relative;
    border-radius: 10px;
}

.widget_search .search-form .form-group {
    margin-bottom: 0;
}

.widget_search .search-form .form-group .form-control {
    height: 60px;
    padding: 0 70px 0 15px;
    border: 0px;
    border-radius: 5px;
}

.widget_search .search-form .submit-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 75px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    font-size: 16px;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #fff;
    -webkit-transition: .3s ease-in;
    -o-transition: .3s ease-in;
    transition: .3s ease-in;
    border: none;
    cursor: pointer;
}

.widget_search .search-form .submit-btn:hover {
    background-color: var(--heading-color);
    color: #fff;
}

.widget_author_meta {
    text-align: center;
}

.widget_author_meta .thumb {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.widget_author_meta .thumb img {
    border-radius: 50%;
}

.widget_author_meta .content .name {
    font-size: 21px;
    font-weight: 700;
}

.widget_author_meta .content p {
    font-size: 15px;
    line-height: 26px;
}

.widget_author_meta .content ul {
    margin-top: 25px;
}

.widget_author_meta .content ul li {
    display: inline-block;
    margin: 0 5px;
}

.widget_author_meta .content ul li a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.widget_author_meta .content ul li a:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.widget_tag_cloud .tagcloud a {
    padding: 6px 15px;
    margin: 5px;
    color: #959595;
    background: #D9D9D9;
    float: left;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    border-radius: 5px;
    font-size: 15px !important;
    letter-spacing: 1.5px;
}

.widget_tag_cloud .widget-title {
    font-size: 24px;
    letter-spacing: 0.6px;
    color: #515151;
    font-weight: 600;
    margin-bottom: 30px;
    line-height: 18px;
}

.widget_tag_cloud .tagcloud a:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.widget ul li ul.sub-menu {
    position: initial;
}

.widget ul li ul.sub-menu {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    border-top: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 10px;
}

.widget ul li ul.sub-menu li a {
    padding: 0 0 0px 10px;
}

.widget ul li ul.sub-menu li a:hover {
    background-color: transparent;
}

.widget.widget_nav_menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.widget.widget_nav_menu ul li {
    margin: 15px 0;
}

.widget.widget_nav_menu ul li:first-child {
    margin-top: 0;
}

.widget.widget_nav_menu ul li:last-child {
    margin-bottom: 0;
}

.widget.widget_nav_menu ul li a {
    border: none;
}

.widget.widget_nav_menu ul li > ul {
    margin-left: 15px;
}

.widget.footer-widget.widget_calendar table {
    margin-bottom: 0;
}

.widget.footer-widget.widget_calendar caption {
    padding-bottom: 0;
}

.widget.footer-widget .widget-title {
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 35px;
}

.widget.footer-widget p,
.footer-widget.widget_tag_cloud .tagcloud a,
.widget.footer-widget.widget_calendar caption,
.widget.footer-widget.widget_calendar th,
.widget.footer-widget.widget_calendar td {
    color: rgba(255, 255, 255, 0.6);
}

.widget.footer-widget ul li a,
.widget.footer-widget ul li {
    color: rgba(255, 255, 255, 0.6);
}

.widget.footer-widget ul li a:hover {
    color: var(--primary-color);
}

.widget .social_share {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.widget .social_share li {
    width: calc(100% / 3);
    margin: 0;
    display: block;
    padding: 20px 0 12px 0;
    text-align: center;
    border-radius: 5px;
    color: #fff;
}

.widget .social_share li i {
    display: block;
    margin-bottom: 10px;
    font-size: 24px;
}

.widget .social_share li a:hover {
    color: #fff;
}

.widget .social_share li + li {
    margin-left: 10px;
}

.widget .recent_post_item {
    margin: 0;
    padding: 0;
    list-style: none;
}

.widget .recent_post_item li {
    display: block;
}

.widget .recent_post_item li + li {
    margin-top: 20px;
}

.widget .recent_post_item li.single-recent-post-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.widget .recent_post_item li.single-recent-post-item .thumb {
    width: 90px;
    margin-right: 15px;
}

.widget .recent_post_item li.single-recent-post-item .thumb img {
    border-radius: 5px;
}

.widget .recent_post_item li.single-recent-post-item .content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.widget .recent_post_item li.single-recent-post-item .content .title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: 0.38px;
    color: #fff;
    text-transform: capitalize;
}

.widget .recent_post_item li.single-recent-post-item .content .desc {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.38px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 28px;
}

.footer-logo {
    margin-top: -15px;
    display: block;
}

.footer-logo img {
    margin-bottom: 40px;
}

.widget .description {
    color: #fff !important;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 0px;
    font-family: var(--secondary-font);
    margin-top: 20px;
}

.widget .input-group .btn {
    background: var(--secondary-color);
    text-transform: uppercase;
    color: #fff;
    padding: 15px;
    font-size: 15px;
}

.widget .input-group .btn:hover {
    background: #515151 !important;
    color: var(--secondary-color) !important;
    -webkit-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.copyright-area {
    padding: 20px 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
}

.widget-testimonial .icon {
    font-size: 70px;
    line-height: 60px;
    color: var(--primary-color);
    margin-bottom: 16px;
}

.widget-testimonial p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
}

.widget-testimonial .author-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-top: 24px;
}

.widget-testimonial .author-meta .thumb {
    margin-right: 20px;
}

.widget-testimonial .author-meta .thumb img {
    max-width: 80px;
    height: 80px;
    border-radius: 50%;
}

.widget-testimonial .author-meta .content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-top: 15px;
}

.widget-testimonial .author-meta .content .name {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
}

.widget-testimonial .author-meta .content .designation {
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
}

/*----------------------------------------------
    # Nav bar
----------------------------------------------*/
.navbar-area {
    padding: 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.navbar-area.nav-absolute {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0px;
    z-index: 1;
}

.navbar-area .nav-container {
    background-color: transparent;
    max-width: 82%;
    padding: 22px 0px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.navbar-area .nav-container .logo a {
    font-family: var(--body-font);
    font-size: 33px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav {
    display: block;
    width: 100%;
    text-align: right;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li {
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 50px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    color: #666;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li:hover a {
    color: #404040;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
    margin-left: 20px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
    position: relative;
    z-index: 0;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    position: absolute;
    font-size: 10px;
    right: 0;
    top: 50%;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: '\f067';
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover {
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    color: var(--primary-color);
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover:before {
    color: var(--primary-color);
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover > .sub-menu {
    visibility: visible;
    opacity: 1;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    position: absolute;
    text-align: left;
    min-width: 260px;
    margin: 0;
    padding: 0 15px;
    list-style: none;
    left: 0;
    top: 100%;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    z-index: 9;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    border-radius: 10px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.border-bt0 {
    border-bottom: 0px !important;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    display: block;
    margin-left: 0;
    line-height: 22px;
    font-size: 15px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
    border-top: 1px solid #e2e2e2;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
    display: block;
    padding: 12px 0px;
    background-color: #fff;
    white-space: nowrap;
    color: #000;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a i {
    opacity: 0;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    margin-right: -18px;
    position: relative;
    color: #F12552;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
    color: var(--primary-color);
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover i {
    opacity: 1;
    margin-right: 5px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children {
    position: relative;
    z-index: 0;
    padding-right: 0px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:before {
    position: absolute;
    right: 15px;
    top: 50%;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: '\f105';
    -ms-transform: translateY(-50%);
    /* IE 9 */
    -webkit-transform: translateY(-50%);
    /* Chrome, Safari, Opera */
    transform: translateY(-50%);
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children > .sub-menu {
    left: 100%;
    top: 20px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children > .sub-menu .sub-menu .sub-menu {
    left: auto;
    right: 100%;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu {
    visibility: visible;
    opacity: 1;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu li:hover:before {
    color: #fff;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav > li {
    padding-right: 10px;
    margin-right: 10px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav > li:hover {
    color: var(--primary-color) !important;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav > li:hover > a {
    color: var(--primary-color) !important;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav > li:hover:before {
    color: var(--primary-color) !important;
}

.navbar-area.black-bg {
    background: var(--heading-color);
    position: relative;
}

.navbar-nav {
    opacity: 0;
    margin-right: -30px;
    visibility: hidden;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.menu-open {
    opacity: 1;
    margin-right: 0;
    visibility: visible;
}

.bar1 {
    width: 32px;
    height: 2px;
    margin-bottom: 5px;
    position: absolute;
    background: #fff;
    z-index: 9999;
    top: 10px;
    right: -5px;
}

.bar2 {
    width: 24px;
    height: 2px;
    margin-bottom: 5px;
    position: absolute;
    background: #fff;
    z-index: 9999;
    top: 17px;
    right: -5px;
}

.bar3 {
    width: 18px;
    height: 2px;
    margin-bottom: 5px;
    position: absolute;
    background: #fff;
    z-index: 9999;
    top: 24px;
    right: -5px;
}

.responsive-mobile-menu button:focus {
    outline: none;
    border: none;
}

@media only screen and (max-width: 991px) {
    .navbar-area .nav-container {
        position: relative;
        z-index: 0;
    }

    .navbar-area .nav-container .navbar-toggler {
        padding: 0px;
    }

    .navbar-area .nav-container .navbar-collapse {
        -webkit-transform: translateX(110%);
        -ms-transform: translateX(110%);
        transform: translateX(110%);
        margin-top: 15px;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        height: 100%;
        height: auto;
        position: absolute;
        top: 70px;
    }

    .navbar-area .nav-container .navbar-collapse.show {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav {
        display: block;
        margin-top: 20px;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li {
        display: block;
        text-align: left;
        line-height: 30px;
        padding: 10px 0;
        border-bottom: 1px solid var(--heading-color);
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li:last-child {
        border-bottom: none;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
        margin-left: 0;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
        top: 25px;
        right: 20px;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover {
        padding-bottom: 0;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover > .sub-menu {
        visibility: visible;
        height: auto;
        opacity: 1;
        background-color: transparent;
        border-bottom: none;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
        position: initial;
        display: block;
        width: 100%;
        border-top: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin-left: 0;
        padding-bottom: 0;
        visibility: hidden;
        opacity: 0;
        height: 0;
        overflow: hidden;
        max-height: 250px;
        overflow-y: scroll;
        -webkit-transition: height 500ms;
        -moz-transition: height 500ms;
        -o-transition: height 500ms;
        transition: height 500ms;
        border-radius: 10px;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .sub-menu .menu-item-has-children:before {
        content: "\f107";
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
        padding: 0;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li.menu-item-has-children:hover:before {
        top: 30px;
        color: #fff;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
        border-top: none;
    }
}

@media only screen and (max-width: 1500px) {
    .navbar-nav {
        text-align: center !important;
    }
}

.nav-right-part ul {
    padding: 0;
    margin: 0;
}

.nav-right-part ul li {
    list-style: none;
    float: left;
    line-height: 1.1;
    margin-right: 25px;
}

.nav-right-part ul li:last-child {
    margin-right: 0px;
}

.nav-right-part ul li.search {
    position: relative;
    padding: 10px 0px 10px 20px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.nav-right-part ul li.search a {
    font-size: 20px;
    color: var(--secondary-color);
}

.nav-right-part ul li.heart {
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.nav-right-part ul li.heart:hover {
    background: #F12552;
}

.nav-right-part ul li.heart:hover i {
    color: #fff;
}

.nav-right-part ul li.heart i {
    color: #F12552;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.nav-right-part ul li.menubar a i:before {
    font-size: 30px;
}

.nav-right-part ul li.menubar:hover a i {
    color: #fff;
    cursor: pointer;
}

.nav-style-02 .nav-container .navbar-collapse .navbar-nav li a {
    color: #fff;
}

.nav-style-02 .nav-container .navbar-collapse .navbar-nav > li:hover > a, .nav-style-02 .nav-container .navbar-collapse .navbar-nav > li:hover:before {
    color: #fff !important;
    opacity: .8;
}

.nav-style-02 .nav-right-part ul li.search a {
    color: #fff;
}

.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    color: #fff !important;
}

.nav-style-02 .nav-right-part li {
    position: relative;
    top: 3px;
}

.nav-style-02 .nav-right-part li a {
    color: #fff;
}

.nav-style-01 .nav-right-part ul li.search {
    padding: 18px 0px 18px 20px;
}

.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    color: #707077;
}

.nav-style-01 {
    background: #fff;
}

.black-nav .nav-right-part ul li.search a, .black-nav .nav-right-part li a {
    color: #404040 !important;
}

.black-nav .bar1, .black-nav .bar2, .black-nav .bar3 {
    background: #404040;
}

@media only screen and (min-width: 992px) {
    .black-nav .navbar-collapse .navbar-nav li a {
        color: #404040 !important;
    }

    .black-nav .nav-container .navbar-collapse .navbar-nav > li:hover > a, .black-nav .nav-container .navbar-collapse .navbar-nav > li:hover:before {
        color: #404040 !important;
    }

    .black-nav .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
        color: #404040 !important;
    }

    .black-nav .nav-right-part ul li.menubar:hover a i {
        color: #404040;
    }

    .navbar-area-fixed .nav-container {
        padding: 10px 0px;
        -webkit-transition: all .4s;
        -o-transition: all .4s;
        transition: all .4s;
    }
}

.nav-style-01.navbar-area-fixed {
    background: #ffffff;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
    box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
}

.nav-style-01 .menubar {
    margin-top: 7px;
}

.nav-style-02 .menubar {
    top: 0px !important;
}

.nav-style-02.navbar-area-fixed {
    background: var(--secondary-color);
    -webkit-box-shadow: 0px 4px 6px 0px rgba(228, 226, 234, 0.06);
    box-shadow: 0px 4px 6px 0px rgba(228, 226, 234, 0.06);
}

.banner-slider {
    min-height: 100vh;
}

.banner-slider [data-animation-in] {
    opacity: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-transition: opacity 0.5s ease 0.3s;
    -o-transition: opacity 0.5s ease 0.3s;
    transition: opacity 0.5s ease 0.3s;
}

.banner-area .container {
    max-width: 90%;
}

.banner-asnavfor-style-01 {
    min-height: 800px;
    position: relative;
    background-color: #fff;
}

.banner-asnavfor-style-01:before {
    position: absolute;
    content: '';
    top: 170px;
    width: 100%;
    height: calc(100% - 170px);
    background-color: var(--secondary-color);
}

.banner-asnavfor-style-01 .slick-prev, .banner-asnavfor-style-01 .slick-next {
    background: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    z-index: 4;
    left: auto;
    right: 4%;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.banner-asnavfor-style-01 .slick-prev:hover, .banner-asnavfor-style-01 .slick-next:hover {
    background: #404040;
}

.banner-asnavfor-style-01 .slick-prev:hover:before, .banner-asnavfor-style-01 .slick-next:hover:before {
    color: #fff;
}

.banner-asnavfor-style-01 .slick-prev::before, .banner-asnavfor-style-01 .slick-next::before {
    font-family: "Font Awesome 5 Pro";
    color: var(--primary-color);
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.banner-asnavfor-style-01 .slick-prev {
    position: absolute;
    top: 82%;
}

.banner-asnavfor-style-01 .slick-prev:before {
    content: "\f104";
}

.banner-asnavfor-style-01 .slick-next {
    top: 74%;
}

.banner-asnavfor-style-01 .slick-next:before {
    content: "\f105";
}

.padding-left-140 {
    padding-left: 140px;
}

.main-banner {
    position: relative;
    background-size: cover;
    background-position: 7% center;
    height: 100vh;
    min-height: 700px;
    background-color: #f6f6f6;

    @include media-breakpoint-up(sm) {
        -ms-background-position-x: left;
        background-position-x: left;
    }

    @include media-breakpoint-up(xl) {
        -ms-background-position-x: center;
        background-position-x: center;
    }
}

.main-banner:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(4, 51, 129, 0.5);
}

.main-banner .title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;

    @include media-breakpoint-up(md) {
        font-size: 4rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 5rem;
    }
}

.main-banner .description {
    font-size: 20px;
    color: #fff;
    letter-spacing: 0;
    font-family: var(--secondary-font);
}

.banner-social {
    position: absolute;
    left: 25px;
    bottom: 25px;

    @include media-breakpoint-up(lg) {
        left: 75px;
        bottom: initial;
    }
}

.banner-social ul {
    margin: 0;
    padding: 0;
}

.banner-social ul li {
    list-style: none;
    margin-bottom: 1rem;
}

.banner-social ul li:last-child {
    margin-bottom: 0;
}

.banner-social ul li a {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1rem;
    line-height: 2.75rem;
    color: #fff;
    text-align: center;
    background: var(--secondary-color);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.banner-social ul li a:hover {
    background: var(--primary-color);
}

.news-item-style-02 .thumb {
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
}

.news-item-style-02 .thumb img {
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.news-item-style-02 .thumb img:hover {
    -webkit-transform: scale(1.1) rotate(2deg);
    -ms-transform: scale(1.1) rotate(2deg);
    transform: scale(1.1) rotate(2deg);
}

.news-item-style-02 .description {
    font-family: var(--secondary-font);
    margin-bottom: 20px;
}

.news-item-style-02 .content {
    padding: 30px;
    position: relative;
}

.news-item-style-02 .blog-sm-text {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--secondary-color);
    font-family: var(--body-font);
    padding-left: 53px;
}

.news-item-style-02 .blog-sm-text:before {
    position: absolute;
    content: '';
    width: 38px;
    height: 2px;
    background: var(--secondary-color);
    left: 0px;
    top: 10px;
}

.news-item-style-02 .blog-lg-text {
    font-size: 24px;
    letter-spacing: 0px;
    font-weight: 500;
    text-transform: none;
    margin-bottom: 20px;
    margin-top: 15px;
    color: #404040;
}

.news-item-style-02 ul {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
}

.news-item-style-02 ul li {
    list-style: none;
    float: left;
}

.news-item-style-02 ul li:first-child a {
    padding-left: 0px;
}

.news-item-style-02 ul li:last-child a {
    border-right: 0px;
}

.news-item-style-02 ul li a {
    font-size: 16px;
    font-family: var(--secondary-font);
    color: #666;
    border-right: 1px solid #666;
    padding: 0px 20px;
}

.news-item-style-02 .small-thumb {
    position: absolute;
    top: -25px;
    right: 30px;
    border-radius: 50%;
    background: #fff;
}

.news-item-style-02 .small-thumb img {
    padding: 5px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.news-item-style-02 .btn-wrapper a {
    color: #404040;
    text-decoration: underline;
    font-size: 18px;
}

.news-item-style-03 {
    -webkit-box-shadow: 0px 10px 20px #2E319211;
    box-shadow: 0px 10px 20px #2E319211;
}

.news-item-style-03 .thumb {
    position: relative;
}

.news-item-style-03 .small-thumb {
    position: absolute;
    bottom: -25px;
    right: 30px;
    border-radius: 50%;
    background: #fff;
}

.news-item-style-03 .small-thumb img {
    padding: 5px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.news-item-style-03 .content {
    padding: 30px;
    position: relative;
}

.news-item-style-03 .subtitle {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--secondary-color);
    font-family: var(--body-font);
    padding-left: 0;
    -webkit-transition: padding .5s;
    -o-transition: padding .5s;
    transition: padding .5s;
}

.news-item-style-03 .subtitle:before {
    position: absolute;
    content: '';
    width: 0px;
    height: 2px;
    background: var(--secondary-color);
    left: 0px;
    top: 10px;
    opacity: 0;
    margin-left: 0px;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    z-index: -1;
}

.news-item-style-03:hover .subtitle {
    padding-left: 45px;
}

.news-item-style-03:hover .subtitle:before {
    opacity: 1;
    margin-left: 0px;
    width: 38px;
}

.news-item-style-03:hover .title a {
    color: var(--secondary-color);
}

.news-item-style-03 .title {
    font-size: 24px;
    letter-spacing: 0px;
    text-transform: none;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #383D49;
}

.news-item-style-03 .date {

}

.news-item-style-03 .date i {
    margin-right: 5px;
    color: var(--primary-color);
}

.swiper-slider .news-item-style-03 {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.hover-style-01 {
    position: relative;
}

.hover-style-01:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 3px;
    background: var(--secondary-color);
    top: 0px;
    left: 0;
    width: 0;
    -webkit-transition: all .6s;
    -o-transition: all .6s;
    transition: all .6s;
}

.hover-style-01:hover:before {
    width: 100%;
    -webkit-transition: all .6s;
    -o-transition: all .6s;
    transition: all .6s;
}

.blog-item-inner {
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.blog-item-inner .publication-card__meta a:hover {
    color: var(--secondary-color);
}

.blog-item-inner .blog-img {
    overflow: hidden;
}

.blog-item-inner .blog-img img {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 100%;
}

.blog-item-inner .blog-img img:hover {
    -webkit-transform: scale(1.1) rotate(2deg);
    -ms-transform: scale(1.1) rotate(2deg);
    transform: scale(1.1) rotate(2deg);
}

.blog-item-inner .blog-detail {
    background: #F9F9FA;
    padding: 20px;
}

.blog-item-inner .blog-detail h5 {
    color: #515151;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.6px;
    margin-bottom: 20px;
}

.blog-item-inner .blog-detail h5 a:hover {
    color: var(--secondary-color);
}

.blog-item-inner .blog-detail p {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.38px;
    color: #515151;
}

.blog-item-inner .blog-detail h6 {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.38px;
    color: #BCBCBC;
    margin-top: 20px;
    margin-bottom: 2px;
}

.publication-card {
    margin-bottom: 2rem;
    position: relative;
    box-shadow: 0 10px 20px #2E319211;

    &:last-child {
        margin-bottom: 0;
    }

    &__cover {
        position: relative;
    }

    &__image {
        display: inline-block;
    }

    &__date {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 60px;
        min-height: 60px;
        padding-top: .625rem;
        padding-bottom: .625rem;
        position: absolute;
        left: 30px;
        bottom: -35px;
        text-align: center;
        background: var(--primary-color);
        border-radius: 5px;
        box-shadow: 0 5px 13px rgba(4, 51, 129, 0.1);

        &:before {
            content: '';
            width: 50px;
            height: 20px;
            position: absolute;
            left: 7px;
            bottom: -6px;
            background: #D92B9C33;
            border-radius: 5px;
        }
    }

    &__day {
        font-size: 1.5rem;
        color: #ffffff;
        line-height: 1;
    }

    &__month {
        font-size: 1rem;
        color: #ffffff;
        line-height: 1;
    }

    &__content {
        padding: 60px 30px 30px;
        position: relative;

        &.no-cover {
            padding-top: 30px;
        }
    }

    &__meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    &__category {
        @include media-breakpoint-up(md) {
            margin-right: 20px;
        }
    }

    &__share {
        display: flex;
        align-items: center;

        span {
            margin-right: 10px;
        }

        ul {
            display: flex;
            margin: 0;
            padding: 0;
        }

        li {
            list-style: none;
        }

        a {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 8px;
            font-size: 14px;
            line-height: 30px;
            color: var(--secondary-color);
            text-align: center;
            background: rgba(4, 51, 129, 0.1);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;

            &:hover {
                color: #fff !important;
                background: var(--secondary-color);
            }
        }
    }

    &__title {
        color: #404040;
        font-size: 1.75rem;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: .5rem;
        line-height: 1.5;
        transition: all .3s ease;

         &:hover {
             color: var(--secondary-color);
         }
    }

    &__abstract {
        font-size: 1rem;
        color: #666;
        font-weight: 400;
        font-family: var(--secondary-font);
        line-height: 1.5;
        margin-bottom: 0;
    }
}

.blog-no-sidebar {
    -webkit-box-shadow: 0px 10px 20px #2E319211;
    box-shadow: 0px 10px 20px #2E319211;
}

.news-slider-1 {
    position: relative;
    min-height: 550px;
}

.swiper-slider {
    max-width: 1400px;
}

.swiper-slider.swiper-container {
    width: 100%;
    padding-bottom: 78px;
    position: relative;
    padding-top: 10px;
}

.swiper-slider.swiper-container .swiper-slide {
    height: auto;
    -webkit-box-shadow: 0px 0px 4px #bdbdbd;
    box-shadow: 0px 0px 4px #bdbdbd;
    background: #fff;
}

.swiper-slider.swiper-container .swiper-slide::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    top: 0;
    z-index: 9;
}

.swiper-slider.swiper-container .swiper-slide .slider-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -ms-grid;
    display: grid;
    text-align: center;
}

.swiper-slider.swiper-container .swiper-button-next, .swiper-slider.swiper-container .swiper-button-prev {
    background: #E4E4E4;
    top: 42%;
    width: 50px;
    height: 60px;
    display: none;
}

.swiper-slider.swiper-container .swiper-button-next:hover, .swiper-slider.swiper-container .swiper-button-prev:hover {
    background: var(--primary-color);
}

.swiper-slider.swiper-container .swiper-button-next i, .swiper-slider.swiper-container .swiper-button-prev i {
    font-size: 40px;
    margin-top: 10px;
}

.swiper-slider.swiper-container .swiper-button-next {
    right: 0%;
    padding-left: 20px;
}

.swiper-slider.swiper-container .swiper-button-prev {
    left: 0%;
    padding-left: 15px;
}

.swiper-slide.swiper-slide-active {
    opacity: 1 !important;
}

.swiper-slide.swiper-slide-active:before {
    display: none;
}

.swiper-pagination-bullet {
    background: transparent none repeat scroll 0 0;
    border: 1px solid var(--heading-color);
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    opacity: 1;
    width: 12px;
}

.swiper-pagination-bullet-active {
    background: var(--primary-color);
    border: medium none;
    height: 12px;
    opacity: 1;
    width: 12px;
}

.blog-details-area {
    margin-top: 140px;
}

.blog-details-area .blog-details {
    background: #F9F9FA;
}

.blog-details-area .blog-details h2 {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.6px;
    color: #515151;
    padding: 20px;
    padding-top: 44px;
}

.blog-details-area .blog-details p {
    color: #515151;
    font-size: 15px;
    letter-spacing: 0.38px;
    font-weight: 400;
    padding: 0px 20px 20px;
}

.blog-details-area .blog-details h6 {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.38px;
    color: #BCBCBC;
    padding-left: 20px;
    padding-top: 2px;
    padding-bottom: 50px;
}

.blog-details-area .blog-details h6 span {
    margin-left: 50px;
}

.latest-posts-widget {
    width: 100%;
}

.latest-item {
    border-bottom: 1px solid rgba(129, 129, 129, 0.25);
    width: 100%;
    padding: 15px 0;
}

.latest-item:last-child {
    border-bottom: 0px;
}

.latest-item .content-part p {
    font-size: 16px;
    font-family: var(--secondary-font);
    color: #404040;
    line-height: 25px;
    font-weight: 400;
}

.latest-item .content-part p:hover {
    color: var(--secondary-color);
}

.latest-item .content-part h4 {
    color: #515151;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.38px;
    font-family: var(--body-font);
}

.latest-item .content-part .text {
    color: #D1D1D1;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    font-family: var(--body-font);
}

.categories-widget {
    &__wrapper {

    }

    &__item {
        height: 200px;
        padding: 1rem;
        background-color: #f4f4f4;
    }

    &__link {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        background-size: cover;
        background-position: center center;
        overflow: hidden;
        transition: all 0.2s;
    }

    &__info {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
        background-color: rgba(18, 18, 19, 0.6);
    }

    &__counter {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: var(--p-bold);
        color: #fff;
    }

    &__title {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;color: #fff;
        margin: 0;
    }
}

.archive-list {

}

.latest-posts {
    &__item {
        margin-bottom: 1.5rem;
    }

    &__header {
        margin-bottom: 1rem;
        position: relative;
    }

    &__image {
        display: inline-block;
        width: 100%;

        img {
            width: 100%;
        }
    }

    &__category {
        padding: 0.5rem 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: .75rem;
        background-color: var(--primary-color);
        color: #ffffff;
    }

    &__body {

    }

    &__title {
        display: block;
        font-size: 1rem;
        font-weight: 600;
        color: var(--heading-color);
    }

    &__meta {
        font-size: .875rem;
        color: var(--paragraph-color);
    }
}

.volunteer-banner {
    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 312px;
        position: relative;
        background-color: #fff;
        background-position: 50%;
        -webkit-background-size: cover;
        background-size: cover;
        -webkit-border-radius: .625rem;
        -moz-border-radius: .625rem;
        border-radius: .625rem;
        overflow: hidden;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background: rgba(4, 51, 129, 0.3);
        }
    }

    &__content {
        position: relative;
        text-align: center;
    }

    &__title {
        color: #fff;
        margin-bottom: .5rem;
    }

    &__button {
        padding: 5px 30px !important;
    }
}

.social-banners {
    &__img {
        display: inline-block;
        width: 100%;
        margin-bottom: 1rem;

        img {
            width: 100%;
        }
    }
}

.history-list {

}

.widget_search .form-control {
    color: #000;
    letter-spacing: 1.5px;
    font-size: 15px;
}

.widget_search .form-control::-webkit-input-placeholder {
    color: #BCBCBC;
}

.widget_search .form-control:-ms-input-placeholder {
    color: #BCBCBC;
}

.widget_search .form-control::-ms-input-placeholder {
    color: #BCBCBC;
}

.widget_search .form-control::placeholder {
    color: #BCBCBC;
}

.widget_search .form-control:focus {
    border: 1px solid var(--secondary-color);
}

.prev-post a, .next-post a {
    font-weight: 400;
}

.prev-post a span, .next-post a span {
    color: #D1D1D1;
}

.brand-area {
    background: #fff;
    z-index: 999;
}

.brant-item {
    width: 20%;
    float: left;
    margin: 50px 0px;
    text-align: center;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.brant-item img {
    max-width: 116px;
    max-height: 92px;
}

.grey-bg .brant-item {
    margin: 70px 0px !important;
}

.politx-content-area {
    position: relative;
}

.politx-content-area .org-img {
    position: absolute;
    top: -100px;
    right: -20%;
}

.organization-content {
    background: rgba(4, 51, 129, 0.05);
    padding: 100px 38% 100px 80px;
}

.vote-area2 {
    background: var(--secondary-color);
    position: relative;
}

.footer-area {
    background: #404040;
}

.footer-area .footer-top .widget.widget_nav_menu ul li a {
    color: #fff !important;
    font-size: 16px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 400;
    -webkit-transition: padding .5s;
    -o-transition: padding .5s;
    transition: padding .5s;
    font-family: var(--secondary-font);
}

@media only screen and (max-width: 1400px) {
    .footer-area .footer-top .widget.widget_nav_menu ul li a {
        padding: 0px 10px;
    }
}

.footer-area .footer-top .widget.widget_nav_menu ul li a:after {
    display: none;
}

.footer-area .footer-top .widget.widget_nav_menu ul li a:hover {
    font-weight: 600;
}

.footer-area .footer-top .widget.widget_nav_menu ul li a:hover i {
    opacity: 1;
    margin-right: 5px;
}

.footer-area .footer-top .widget.widget_nav_menu ul li a i {
    font-size: 12px;
    position: relative;
    top: -2px;
    opacity: 0;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    margin-right: -15px;
}

.footer-area .copyright-area-inner {
    font-size: 15px;
    padding: 20px 0px;
    font-family: var(--secondary-font);
    font-weight: 400;
    color: #fff;
    background: #113D87;
    text-align: center;
}

.footer-area .copyright-area-inner a {
    font-weight: 600;
}

.footer-area .copyright-area-inner i {
    color: #F12552;
}

.footer-style-2 .footer-logo img {
    margin-top: 0px;
}

.footer-bg {
    background: url(../images/bg/footer.html);
    background-position: 50% 15%;
}

.contact-widget {
    background-color: #fff !important;
    padding: 80px 30px 65px 30px !important;
    -webkit-box-shadow: 0px 25px 80px #0000001F;
    box-shadow: 0px 25px 80px #0000001F;
    margin-top: -45px;
    margin-bottom: 45px;
}

.contact-widget p {
    color: #666 !important;
    font-size: 16px;
    letter-spacing: 0px;
    font-family: var(--secondary-font);
}

.contact-widget .contact_info_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.contact-widget .contact_info_list li {
    display: block;
}

.contact-widget .contact_info_list li.single-info-item {
    position: relative;
    margin-bottom: 15px;
}

.contact-widget .contact_info_list li.single-info-item .icon {
    position: absolute;
    left: 0;
    top: 0px;
    color: #666;
}

.contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 30px;
    color: #666;
    font-size: 16px;
    font-family: var(--secondary-font);
}

.contact-widget .footer-social {
    display: flex;
    margin: 42px 0 0;
    padding: 0;
}

.contact-widget .footer-social li {
    list-style: none;
    margin-right: 1rem;
}

.contact-widget .footer-social li:last-child {
    margin-right: 0;
}

.contact-widget .footer-social li a {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1rem;
    line-height: 2.75rem;
    color: var(--secondary-color) !important;
    text-align: center;
    background: rgba(4, 51, 129, 0.1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.contact-widget .footer-social li a:hover {
    color: #fff !important;
    background: var(--secondary-color);
}

.instagram-feed {
    margin: 0;
    padding: 0;
}

.instagram-feed li {
    list-style: none;
    float: left;
    margin: 0 7px 7px 0px !important;
    overflow: hidden;
}

.instagram-feed li a img {
    max-width: 80px;
    border-radius: 5px;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.instagram-feed li a img:hover {
    -webkit-transform: scale(1.1) rotate(2deg);
    -ms-transform: scale(1.1) rotate(2deg);
    transform: scale(1.1) rotate(2deg);
    border-radius: 18px;
}

.margin-lg-top-80 {
    margin-top: 80px;
}

.party-box-wrapper {
    border: 2px solid #F5F5F5;
    padding: 75px 100px 65px 100px;
    background: #f6f6f6;
}

.party-box-content h4 {
    font-size: 55px;
    line-height: 60px;
    font-weight: 700;
}

.party-box-content p {
    font-size: 16px;
    font-family: var(--secondary-font);
    color: #666;
    line-height: 30px;
    margin-top: 15px;
}

.about-banner {
    position: relative;
    margin-bottom: 50px;
}

.about-banner .img-wrapper {
    position: absolute;
    top: -260px;
    left: 33%;
}

.about-banner .img-wrapper img {
    max-width: 60%;
}

.causes-details {
    -webkit-box-shadow: 0px 10px 20px #2E319211;
    box-shadow: 0px 10px 20px #2E319211;
}

.publication-card__category {
    background: #E5EAF2;
    font-size: 16px;
    padding: 3px 10px;
    border-radius: 4px;
}

.publication-card__category a, .publication-card__category span {
    color: var(--secondary-color);
}

.causes-content {
    padding: 30px;
}

.causes-content h4 {
    color: #404040;
    font-size: 30px;
    letter-spacing: 0;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 5px;
}

.causes-content p {
    font-size: 16px;
    letter-spacing: 0px;
    color: #666;
    font-weight: 400;
    font-family: var(--secondary-font);
    line-height: 30px;
}

.causes-content-box .cbox-item p {
    color: #7d7977;
}

.causes-mission h5 {
    font-size: 24px;
    font-weight: 400;
}

.error-content-area {
    position: relative;
    min-height: 700px;
}

.error-content-area:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(4, 51, 129, 0.7);
    content: '';
}

.error-content {
    z-index: 9;
}

.error-content h2 {
    color: #fff;
    font-size: 329px;
    font-weight: 900;
    line-height: 300px;
}

.error-content h6 {
    font-size: 30px;
    color: #fff;
    line-height: 55px;
}

.error-content .error-social {
    padding: 0;
    margin: 42px 0 0;
    display: flex;
    position: relative;
    top: 110px;
}

.error-content .error-social li {
    list-style: none;
    margin-right: 1rem;
}

.error-content .error-social li:last-child {
    margin-right: 0;
}

.error-content .error-social li a {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1rem;
    line-height: 2.75rem;
    color: #fff !important;
    text-align: center;
    background: #999FAA;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.error-content .error-social li a:hover {
    color: #fff !important;
    background: var(--secondary-color);
}

.support-area {
    padding-top: 115px;
    padding-bottom: 105px;
}

.location-area {
    padding-top: 115px;
    padding-bottom: 20px;
}

.location-content p {
    font-family: var(--secondary-font);
    color: #666;
    line-height: 35px;
    margin-bottom: 50px;
}

/*##################################*/
.countdown {
    height: 100%;
    /* Reset */
    /* Skeleton */
    /* Colon Seperator - IE */
    /* Colon Seperator */
}

.countdown * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.countdown .countdownFix {
    z-index: 3 !important;
}

.countdown .clockFix {
    z-index: 1 !important;
}

.countdown li span .inn {
    /*backface-visibility*/
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.countdown ul {
    list-style: none;
}

.countdown li {
    line-height: 87px;
}

.countdown ul.flip {
    position: relative;
    float: left;
    height: 100%;
    width: 50px !important;
    font-size: 50px;
    font-weight: bold;
}

@media only screen and (max-width: 540px) {
    .countdown ul.flip {
        width: 25px !important;
        font-size: 29px !important;
        height: 83% !important;
    }

    .countdown ul.flip li {
        line-height: 37px !important;
    }

    .countdown ul.flip:nth-child(2n+2):not(:last-child):after {
        top: -24% !important;
    }
}

@media only screen and (max-width: 440px) {
    .countdown ul.flip {
        width: 22px !important;
        font-size: 23px !important;
        height: 66% !important;
    }

    .countdown ul.flip li {
        line-height: 30px !important;
    }

    .countdown ul.flip:nth-child(2n+2):not(:last-child):after {
        font-size: 28px !important;
    }
}

.countdown ul.nth-child-2np2-notlast:after, .countdown ul.flip:nth-child(2n+2):not(:last-child):after {
    position: absolute;
    top: 20%;
    right: -13px;
    display: block;
    color: #fff;
    font-size: 46px;
}

.countdown ul.nth-child-2np2-notlast {
    margin-right: 14px;
}

.countdown ul.flip:nth-child(2n+2) {
    margin-right: 12px;
}

.countdown ul.flip li {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 60px;
}

.countdown ul.flip li span {
    display: block;
    height: 100%;
    /*perspective*/
    -webkit-perspective: 200px;
    -moz-perspective: 200px;
    -ms-perspective: 200px;
    -o-perspective: 200px;
    perspective: 200px;
}

.countdown ul.flip li span div {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
}

.countdown ul.flip li span div .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.countdown ul.flip li span div.up {
    /*transform-origin*/
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    top: 0;
}

.countdown ul.flip li span div.up:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 3px;
    background-color: #9AA3B1;
}

.countdown ul.flip li span div.down {
    /*transform-origin*/
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    bottom: 0;
}

.countdown ul.flip li span div div.inn {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 200%;
    color: #fff;
    text-align: center;
    background-color: #ccc;
}

.countdown ul.flip li span div.up div.inn {
    top: 0;
}

.countdown ul.flip li span div.down div.inn {
    bottom: 0;
}

.countdown ul.flip li span div.up:after {
    display: none;
}

.countdown ul.flip.seconds li span div.up:after {
    display: block;
}

/* PLAY */
.play ul li.previous {
    z-index: 2;
}

.play ul li.current {
    /*animation*/
    -webkit-animation: asd .5s .5s linear both;
    -moz-animation: asd .5s .5s linear both;
    -ms-animation: asd .5s .5s linear both;
    -o-animation: asd .5s .5s linear both;
    animation: asd .5s .5s linear both;
    z-index: 3;
}

@-webkit-keyframes asd {
    0% {
        z-index: 2;
    }
    5% {
        z-index: 4;
    }
    100% {
        z-index: 4;
    }
}

@-moz-keyframes asd {
    0% {
        z-index: 2;
    }
    5% {
        z-index: 4;
    }
    100% {
        z-index: 4;
    }
}

@-ms-keyframes asd {
    0% {
        z-index: 2;
    }
    5% {
        z-index: 4;
    }
    100% {
        z-index: 4;
    }
}

@-o-keyframes asd {
    0% {
        z-index: 2;
    }
    5% {
        z-index: 4;
    }
    100% {
        z-index: 4;
    }
}

@keyframes asd {
    0% {
        z-index: 2;
    }
    5% {
        z-index: 4;
    }
    100% {
        z-index: 4;
    }
}

.play ul li.current .down {
    z-index: 2;
    /*animation*/
    -webkit-animation: turn .5s .5s linear both;
    -moz-animation: turn .5s .5s linear both;
    -ms-animation: turn .5s .5s linear both;
    -o-animation: turn .5s .5s linear both;
    animation: turn .5s .5s linear both;
}

@-webkit-keyframes turn {
    0% {
        -webkit-transform: rotateX(90deg);
    }
    100% {
        -webkit-transform: rotateX(0deg);
    }
}

@-moz-keyframes turn {
    0% {
        -moz-transform: rotateX(90deg);
    }
    100% {
        -moz-transform: rotateX(0deg);
    }
}

@-ms-keyframes turn {
    0% {
        -ms-transform: rotateX(90deg);
    }
    100% {
        -ms-transform: rotateX(0deg);
    }
}

@-o-keyframes turn {
    0% {
        -o-transform: rotateX(90deg);
    }
    100% {
        -o-transform: rotateX(0deg);
    }
}

@keyframes turn {
    0% {
        -webkit-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }
}

.play ul li.previous .up {
    z-index: 2;
    /*animation*/
    -webkit-animation: turn2 .5s linear both;
    -moz-animation: turn2 .5s linear both;
    -ms-animation: turn2 .5s linear both;
    -o-animation: turn2 .5s linear both;
    animation: turn2 .5s linear both;
}

@-webkit-keyframes turn2 {
    0% {
        -webkit-transform: rotateX(0deg);
    }
    100% {
        -webkit-transform: rotateX(-90deg);
    }
}

@-moz-keyframes turn2 {
    0% {
        -moz-transform: rotateX(0deg);
    }
    100% {
        -moz-transform: rotateX(-90deg);
    }
}

@-ms-keyframes turn2 {
    0% {
        -ms-transform: rotateX(0deg);
    }
    100% {
        -ms-transform: rotateX(-90deg);
    }
}

@-o-keyframes turn2 {
    0% {
        -o-transform: rotateX(0deg);
    }
    100% {
        -o-transform: rotateX(-90deg);
    }
}

@keyframes turn2 {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }
    100% {
        -webkit-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }
}

/* SHADOW */
.play ul li.previous .up .shadow {
    /*linear-gradient*/
    /*animation*/
    -webkit-animation: show .5s linear both;
    -moz-animation: show .5s linear both;
    -ms-animation: show .5s linear both;
    -o-animation: show .5s linear both;
    animation: show .5s linear both;
}

.play ul li.current .up .shadow {
    /*linear-gradient*/
    /*animation*/
    -webkit-animation: hide .5s .3s linear both;
    -moz-animation: hide .5s .3s linear both;
    -ms-animation: hide .5s .3s linear both;
    -o-animation: hide .5s .3s linear both;
    animation: hide .5s .3s linear both;
}

/*DOWN*/
.play ul li.previous .down .shadow {
    /*linear-gradient*/
    /*animation*/
    -webkit-animation: show .5s linear both;
    -moz-animation: show .5s linear both;
    -ms-animation: show .5s linear both;
    -o-animation: show .5s linear both;
    animation: show .5s linear both;
}

.play ul li.current .down .shadow {
    /*linear-gradient*/
    /*animation*/
    -webkit-animation: hide .5s .3s linear both;
    -moz-animation: hide .5s .3s linear both;
    -ms-animation: hide .5s .3s linear both;
    -o-animation: hide .5s .3s linear both;
    animation: hide .5s .3s linear both;
}

@-webkit-keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.action-soon-content {
    position: relative;
    padding-left: 50px;
}

.action-soon-content:before {
    position: absolute;
    content: '';
    width: calc(100% - 35px);
    height: calc(100% - 20px);
    border: 2px solid #F5F5F5;
    top: 40px;
    left: 12px;
    z-index: -1;
    border-radius: 10px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.action-soon-content .act-img img {
    border-radius: 10px;
}

.action-soon-content .subtitle {
    font-size: 16px;
    color: var(--secondary-color);
    letter-spacing: 0;
    margin-bottom: 0px;
    margin-top: 24px;
}

.action-soon-content .title {
    font-size: 24px;
    color: #404040;
    font-weight: 500;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.action-soon-content .readMore {
    font-size: 18px;
    color: #666;
}

.action-soon-content:hover .title, .action-soon-content:hover .subtitle {
    color: #fff;
}

.action-soon-content:hover:before {
    background: var(--secondary-color);
}

.action-soon-content:hover .btn-wrapper, .action-soon-content:hover .readMore {
    color: #fff;
}

.choose-box {
    margin-top: 10px;
}

.choose-box .ch-icon {
    margin-bottom: 10px;
}

.choose-box .ch-icon img {
    width: 55px;
}

.choose-box .ch-icon i:before {
    font-size: 40px;
    color: var(--secondary-color);
}

.choose-box h4 {
    margin-top: 15px;
}

.choose-box p {
    font-family: var(--secondary-font);
}

.choose-content .btn-wrapper {
    margin-top: 30px;
}

.choose-content .btn-wrapper:hover {
    color: var(--secondary-color);
}

.choose-content .btn-wrapper a {
    text-decoration: underline;
    font-size: 18px;
}

.choose-content .btn-wrapper:hover a {
    color: var(--secondary-color);
}

.movement-area {
    background: var(--secondary-color);
    padding: 130px 0px;
    position: relative;
}

.movement-area:before {
    position: absolute;
    content: '';
    width: 450px;
    height: 450px;
    background: rgba(255, 255, 255, 0.1);
    right: -6%;
    top: 25%;
    border-radius: 50%;
}

.movement-area:after {
    position: absolute;
    content: '';
    width: 57px;
    height: 57px;
    background: rgba(255, 255, 255, 0.1);
    right: 14%;
    top: 71%;
    border-radius: 50%;
}

.movement-box ul {
    padding: 0;
    margin: 0;
    margin-top: 30px;
}

.movement-box ul li {
    list-style: none;
    margin: 15px 0px;
}

.movement-box ul li a {
    font-family: var(--secondary-font);
    letter-spacing: 0;
    color: #fff;
    font-weight: 400;
    line-height: 22px;
    font-size: 16px;
}

.movement-box ul li a i {
    width: 30px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.movement-left-box {
    text-align: right;
}

.movement-left-box img {
    border-radius: 15px;
    margin: 10px 0px;
}

.movement-right-box img {
    border-radius: 15px;
    margin: 10px 0px;
}

.log-content {
    margin-top: 50px;
    -webkit-box-shadow: 0px 20px 50px #2E319211;
    box-shadow: 0px 20px 50px #2E319211;
    background: #fff;
    padding: 70px 60px;
    position: relative;
    border-radius: 10px;
}

.log-content h4 {
    font-size: 40.78px;
    letter-spacing: 0;
    color: var(--secondary-color);
}

.log-content p {
    font-family: var(--secondary-font);
    font-size: 16px;
    color: #666;
    letter-spacing: 0;
}

.log-content ul {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 15px;
}

.log-content ul li {
    width: 50px;
    height: 50px;
    letter-spacing: 0;
    text-align: center;
    font-size: 18px;
    border-radius: 50%;
    line-height: 50px;
    float: left;
    list-style: none;
    background: var(--secondary-color);
    color: #fff;
    margin-right: 15px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    -webkit-box-shadow: 0px 5px 10px #0F3E6F1A;
    box-shadow: 0px 5px 10px #0F3E6F1A;
    cursor: pointer;
}

.log-content ul li:nth-child(1) {
    background: #fff;
    color: var(--secondary-color);
}

.log-content ul li:nth-child(2) {
    background: var(--secondary-color);
}

.log-content ul li:last-child {
    margin-right: 0px;
    background: #52ABF1;
}

.log-content ul li:hover {
    background: #fff;
    color: var(--secondary-color);
}
/*---------------------------------------
    ## Back Top
---------------------------------------*/
.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    color: var(--primary-color);
    background-color: #fff;
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    z-index: 99;
    font-size: 30px;
    cursor: pointer;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: none;
}

.back-to-top i {
    -webkit-animation: scrolltop 2s infinite;
    animation: scrolltop 2s infinite;
}

@-webkit-keyframes scrolltop {
    from {
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
        filter: alpha(opacity=0);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    10% {
        -webkit-transform: translate3d(0, -3px, 0);
        transform: translate3d(0, -3px, 0);
        filter: alpha(opacity=100);
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    to {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
        filter: alpha(opacity=0);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

@keyframes scrolltop {
    from {
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
        filter: alpha(opacity=0);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    10% {
        -webkit-transform: translate3d(0, -3px, 0);
        transform: translate3d(0, -3px, 0);
        filter: alpha(opacity=100);
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    to {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
        filter: alpha(opacity=0);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

/*-------------------------
    ## Preloader Css
---------------------------*/
.pre-wrap {
    position: fixed;
    content: '';
    -webkit-transform: translate(-100%, -240%);
    -ms-transform: translate(-100%, -240%);
    transform: translate(-100%, -240%);
    font-size: 62px;
}

.preloader-inner {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999999999;
    background-color: rgba(2, 48, 71, 0.8);
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.preloader-inner .cancel-preloader {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.preloader-inner .cancel-preloader a {
    background-color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--primary-color);
    width: 200px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 30px;
    display: block;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.preloader-inner .cancel-preloader a:hover {
    background-color: var(--heading-color);
    color: #fff;
}

.spinner {
    margin: 120px auto;
    width: 60px;
    height: 60px;
    position: relative;
    text-align: center;
    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: var(--primary-color);
    border-radius: 100%;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

/*----------------------------------------
  ## Search Popup
----------------------------------------*/
.search-popup.active .search-form {
    visibility: visible;
    opacity: 1;
}

.search-popup .search-form {
    min-width: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    visibility: hidden;
    opacity: 0;
    -ms-transform: translate(-50%, -50%);
    /* IE 9 */
    -webkit-transform: translate(-50%, -50%);
    /* Chrome, Safari, Opera */
    transform: translate(-50%, -50%);
    z-index: 99999;
}

.search-popup .search-form .form-group .form-control {
    border: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 54px;
    padding: 0 100px 0 30px;
}

.search-popup .search-form .submit-btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    width: 60px;
    height: 54px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--primary-color);
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    padding: 0;
    text-align: center;
    color: #fff;
}

.search-popup .search-form .submit-btn:hover {
    background-color: var(--heading-color);
}

.body-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.54);
    z-index: 99999;
    content: '';
    left: 100%;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
}

.body-overlay.active {
    visibility: visible;
    opacity: .80;
    left: 0;
}

.sidebar-menu {
    width: 0;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: #fff;
    -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
    z-index: 99999;
    padding: 100px 30px 30px 30px;
    overflow-y: auto;
    -webkit-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
    -o-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
    transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
    opacity: 0;
    visibility: hidden;
}

.sidebar-menu .sidebar-inner {
    position: relative;
    top: 150px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease-in .3s;
    -o-transition: all .3s ease-in .3s;
    transition: all .3s ease-in .3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.sidebar-menu .sidebar-inner .sidemenu-text p {
    font-family: var(--secondary-font);
}

.sidebar-menu .sidebar-menu-close {
    background-color: var(--secondary-color);
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
}

.sidebar-menu .sidebar-menu-close i {
    font-size: 18px;
    color: #fff;
}

.sidebar-menu .sidebar-logo {
    margin-bottom: 23px;
}

.sidebar-menu .sidebar-contact {
    margin-top: 33px;
}

.sidebar-menu .sidebar-contact h4 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
}

.sidebar-menu .sidebar-contact ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-menu .sidebar-contact ul li {
    font-size: 16px;
    line-height: 35px;
    color: #404040;
    font-family: var(--secondary-font);
}

.sidebar-menu .sidebar-contact ul li i {
    font-size: 16px;
    margin-right: 5px;
    width: 25px;
}

.sidebar-menu .sidebar-subscribe {
    margin-top: 35px;
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.sidebar-menu .sidebar-subscribe input[type='text'] {
    border: 1px solid #CCD3DC;
    border-right: 0;
    height: 45px;
    padding: 0 20px 0 20px;
}

.sidebar-menu .sidebar-subscribe button {
    background: rgba(4, 51, 129, 0.5);
    border: 0;
    height: 45px;
    width: 46px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
}

.sidebar-menu .sidebar-subscribe button:hover {
    background: var(--secondary-color);
}

.sidebar-menu .sidebar-subscribe button:focus {
    outline: 0;
}

.sidebar-menu .sidebar-subscribe button i {
    color: #fff;
}

.sidebar-menu .social-link {
    margin-top: 20px;
}

.sidebar-menu .social-link ul {
    margin: 0;
    padding: 0;
}

.sidebar-menu .social-link ul li {
    list-style: none;
    float: left;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(4, 51, 129, 0.1);
    line-height: 43px;
    text-align: center;
    margin-right: 15px;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    cursor: pointer;
}

.sidebar-menu .social-link ul li:hover {
    background: var(--secondary-color);
    color: #fff;
}

.sidebar-menu.active {
    width: 365px;
    opacity: 1;
    visibility: visible;
}

.sidebar-menu.active .sidebar-inner {
    top: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 1s ease-out 1.2s;
    -o-transition: all 1s ease-out 1.2s;
    transition: all 1s ease-out 1.2s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

/*---------------------------------------
    ## Button
---------------------------------------*/
.btn {
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.btn-custom-default {
    border-radius: 5px;
    border: 1px solid #fff;
    font-family: var(--body-font);
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 33px;
    color: #fff;
    padding: 13px 35px;
}

.btn-custom-default:hover {
    border: 1px solid #fff;
    background: #fff;
}

.btn-custom-white {
    border-radius: 5px;
    border: 1px solid #fff;
    font-family: var(--body-font);
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 33px;
    color: #404040;
    padding: 13px 35px;
    background: #fff;
}

.btn-custom-white:hover {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
}

.btn-custom-primary {
    color: rgba(4, 51, 129, 0.8);
    background: rgba(4, 51, 129, 0.2);
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 26px;
    border-radius: 5px;
    padding: 15px 30px;
    font-weight: 500;
}

.btn-custom-primary:hover {
    border: 1px solid #fff;
    color: #fff !important;
    background: var(--secondary-color);
}

.btn-red {
    color: #fff;
    background: #B22234;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 26px;
    border-radius: 5px;
    padding: 15px 30px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.btn-red:hover, .btn-red:focus {
    background: var(--secondary-color);
    color: #fff;
}

.btn-element {
    letter-spacing: 0;
    font-family: var(--body-font);
    font-weight: 400;
    border-radius: 4px;
}

.btn-rounded {
    border-radius: 30px;
}

.btn-main-color {
    background: var(--primary-color);
    color: #fff;
}

.btn-main-color:hover {
    -webkit-box-shadow: 0px 7px 10px rgba(136, 193, 62, 0.5);
    box-shadow: 0px 7px 10px rgba(136, 193, 62, 0.5);
}

.btn-black-color {
    background: #404040;
    color: #fff;
}

.btn-black-color:hover {
    -webkit-box-shadow: 0px 7px 20px rgba(22, 22, 22, 0.5);
    box-shadow: 0px 7px 20px rgba(22, 22, 22, 0.5);
    color: #fff;
}

.btn-transparent-color {
    background: transparent;
    border: 1px solid #404040;
    color: #404040;
}

.btn-transparent-color:hover {
    -webkit-box-shadow: 0px 7px 20px rgba(22, 22, 22, 0.5);
    box-shadow: 0px 7px 20px rgba(22, 22, 22, 0.5);
    color: #404040;
}

.btn-transparent-color:active {
    color: #404040;
}

.btn-active {
    background: transparent;
    border: 1px solid var(--secondary-color);
    color: #404040;
}

.btn-active:hover {
    -webkit-box-shadow: 0px 7px 20px rgba(22, 22, 22, 0.5);
    box-shadow: 0px 7px 20px rgba(22, 22, 22, 0.5);
    color: #404040;
}

.btn-normal-size {
    font-size: 15px;
    padding: 14px 21px;
}

.btn-medium-size {
    font-size: 18px;
    padding: 14px 22px;
}

.btn-lg-size {
    font-size: 19px;
    padding: 14px 21px;
}

.btn-second {
    color: #404040;
    border: 1px solid #404040;
}

.btn-second:hover {
    opacity: 0.5;
    color: #404040;
}

.btn-active {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.btn-active:hover {
    opacity: 0.5;
    color: var(--primary-color);
}

.btn-disabled {
    color: #D5D5D5;
    border: 1px solid #D5D5D5;
}

.btn-disabled:hover {
    opacity: 0.5;
    color: #D5D5D5;
}

.btn-slider {
    background: var(--secondary-color);
    font-size: 26px;
    padding: 8px 55px;
    color: #404040;
    letter-spacing: 8.5px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
}

.btn.focus, .btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-wrapper {
    display: block;
}

.btn-wrapper .boxed-btn {
    background-color: var(--primary-color);
    color: #fff;
    display: inline-block;
    padding: 13px 40px;
    min-width: 160px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    font-weight: 600;
}

.btn-wrapper .boxed-btn.white:hover {
    background-color: #fff;
    color: var(--primary-color);
}

.btn-wrapper .boxed-btn:hover {
    background-color: var(--heading-color);
}

.btn-wrapper .boxed-btn.btn-rounded {
    border-radius: 30px;
}

.btn-wrapper .boxed-btn.reverse-color {
    background-color: var(--heading-color);
}

.btn-wrapper .boxed-btn.reverse-color:hover {
    background-color: var(--primary-color);
}

.desktop-center {
    text-align: center;
}

.desktop-left {
    text-align: left;
}

.desktop-right {
    text-align: right;
}

@media only screen and (max-width: 768px) {
    .tablet-center {
        text-align: center;
    }

    .tablet-left {
        text-align: left;
    }

    .tablet-right {
        text-align: right;
    }
}

@media only screen and (max-width: 414px) {
    .mobile-center {
        text-align: center;
    }

    .mobile-left {
        text-align: left;
    }

    .mobile-right {
        text-align: right;
    }
}

.section-subtitle {
    position: relative;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    color: var(--secondary-color);
    font-family: var(--body-font);
    padding-left: 53px;
}

.section-subtitle:before {
    position: absolute;
    content: '';
    width: 38px;
    height: 2px;
    background: var(--secondary-color);
    left: 0;
    top: 16px;
}

.subtitle-style2 {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: var(--secondary-color);
    font-family: var(--body-font);
}

.section-title {
    font-size: 55px;
    letter-spacing: 0px;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 10px;
    color: #383D49;
    line-height: 60px;
}

.section-subtitle-wh {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #fff;
    font-family: var(--body-font);
    padding-left: 53px;
}

.section-subtitle-wh:before {
    position: absolute;
    content: '';
    width: 38px;
    height: 2px;
    background: #fff;
    left: 0px;
    top: 10px;
}

.section-title-wh {
    font-size: 55px;
    letter-spacing: 0px;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 10px;
    color: #fff;
}

.section-subtitle-sm {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--secondary-color);
    font-family: var(--body-font);
    padding-left: 53px;
}

.section-subtitle-sm:before {
    position: absolute;
    content: '';
    width: 38px;
    height: 2px;
    background: var(--secondary-color);
    left: 0px;
    top: 10px;
}

.section-title-sm {
    font-size: 24px;
    letter-spacing: 0px;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 10px;
    color: #383D49;
}

.slideRotateFromLeft {
    -webkit-animation-name: slideRotateFromLeft;
    animation-name: slideRotateFromLeft;
}

@-webkit-keyframes slideRotateFromLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0) rotate(90deg);
        transform: translate3d(-100%, 0, 0) rotate(90deg);
        visibility: visible;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
        transform: translate3d(0, 0, 0) rotate(90deg);
        visibility: visible;
    }
}

@keyframes slideRotateFromLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0) rotate(90deg);
        transform: translate3d(-100%, 0, 0) rotate(90deg);
        visibility: visible;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
        transform: translate3d(0, 0, 0) rotate(90deg);
        visibility: visible;
    }
}

.slideRotateFromRight {
    -webkit-animation-name: slideRotateFromRight;
    animation-name: slideRotateFromRight;
}

@-webkit-keyframes slideRotateFromRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0) rotate(90deg);
        transform: translate3d(100%, 0, 0) rotate(90deg);
        visibility: visible;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
        transform: translate3d(0, 0, 0) rotate(90deg);
        visibility: visible;
    }
}

@keyframes slideRotateFromRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0) rotate(90deg);
        transform: translate3d(100%, 0, 0) rotate(90deg);
        visibility: visible;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
        transform: translate3d(0, 0, 0) rotate(90deg);
        visibility: visible;
    }
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

.volunteer-form {
    margin-top: 45px;
}

.volunteer-form .form-control {
    padding: 17px 20px;
}

.volunteer-form .form-control::-webkit-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.volunteer-form .form-control:-ms-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.volunteer-form .form-control::-ms-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.volunteer-form .form-control::placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.volunteer-form .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #ced4da;
}

.contact-form {
    margin-top: 50px;
    -webkit-box-shadow: 0px 20px 50px #2E319211;
    box-shadow: 0px 20px 50px #2E319211;
    background: #fff;
    padding: 70px 60px;
    position: relative;
    border-radius: 10px;
}

.contact-form .form-control {
    padding: 17px 20px;
}

.contact-form .form-control::-webkit-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.contact-form .form-control:-ms-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.contact-form .form-control::-ms-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.contact-form .form-control::placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.contact-form .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #ced4da;
}

.mapouter {
    position: relative;
    text-align: right;
    height: 500px;
    width: 100%;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 100%;
}

.log-form {
    margin-top: 30px;
}

.log-form::before {
    position: absolute;
    content: '';
    width: 80%;
    left: 10%;
    bottom: -30px;
    height: 100%;
    z-index: -1;
    background: #F6F6FA;
    opacity: .5;
    border-radius: 20px;
}

.log-form .form-control {
    padding: 17px 20px;
}

.log-form .form-control::-webkit-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.log-form .form-control:-ms-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.log-form .form-control::-ms-input-placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.log-form .form-control::placeholder {
    color: #D1D1D1;
    font-weight: 400;
}

.log-form .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #ced4da;
}

.log-form p {
    font-family: var(--body-font);
    font-size: 18px;
    color: #404040;
    margin-top: 20px;
}

.log-form p a {
    color: var(--secondary-color);
    text-decoration: underline;
}

.nice-select {
    padding-right: 35px;
}

.nice-select:after {
    right: 20px;
}

/*-----------------------------
    Accordion Item
-----------------------------*/
.accordion-content {
    width: 100%;
}

.accordion-content .card {
    background-color: transparent;
    border: 1px solid #D5D5D5;
}

.accordion-content .card + .card {
    margin-top: 20px;
}

.accordion-content .card .card-header {
    background-color: transparent;
    padding: 0;
    border: none;
}

.accordion-content .card .card-header a {
    display: block;
    font-size: 15px;
    line-height: 28px;
    padding: 25px 30px;
    color: #F5F5F5;
    text-decoration: none;
    font-family: var(--body-font);
    letter-spacing: 0.15px;
    font-weight: 400;
    padding-right: 50px;
    cursor: pointer;
    position: relative;
}

.accordion-content .card .card-header a:after {
    position: absolute;
    right: 30px;
    top: 25px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f106";
    font-size: 35px;
}

.accordion-content .card .card-header a[aria-expanded="false"]:after {
    content: "\f107";
}

.accordion-content .card .card-body {
    padding: 0 30px 20px;
    color: #F5F5F5;
    text-decoration: none;
    font-family: var(--body-font);
    font-size: 15px;
    letter-spacing: 0.15px;
    font-weight: 400;
}

.table-responsive {
    display: inline-table !important;
}

.floating-icon {
    display: none;
    right: 45px;
    margin-top: 45px;
    border-radius: 5px;
    background: #fff;
    z-index: 999;
    text-align: center;
    color: #000;
    padding: 12px 0px;
    float: right;
    position: -webkit-sticky;
    position: sticky;
    top: 6vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.16);
    box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.16);
}

.floating-icon__is {
    padding: 5px 15px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.floating-icon__is i:before {
    font-size: 24px;
    margin-left: 0px;
}

.floating-icon__is:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.info-popup {
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: rgba(51, 63, 70, 0.9);
    position: fixed;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.info-popup.active {
    opacity: 1;
    z-index: 999;
}

.info-popup.active .info-popup-content {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.info-popup-content {
    margin: auto;
    width: 50%;
    position: relative;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.info-popup-content__img {
    width: 100%;
    height: 100%;
}

.info-popup-content__img--one {
    background-image: url(../images/modal/one.html);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.info-popup-content__img--two {
    background-image: url(../images/blog-bg.html);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.info-popup-content__img--three {
    background-image: url(../images/slide-three.html);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.info-popup-content__text {
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    background: #fff;
}

.info-popup-content__text-header {
    margin-bottom: 30px;
}

.info-popup-content__text-body {
    margin-bottom: 30px;
}

.info-popup-content__text-body .divide {
    width: 50px;
    height: 1px;
    background: var(--paragraph-color);
    display: inline-block;
}

.info-popup-content__text-is {
    display: block;
    text-transform: capitalize;
    color: #404040;
}

.info-popup-content__title {
    margin-top: 0;
    margin-bottom: 0;
}

.info-popup-content_close {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    background: var(--primary-color);
    display: inline-block;
    border-radius: 30px;
    border: none;
    color: white;
    opacity: 1;
    z-index: 1;
}

.info-popup-content_close:hover {
    cursor: pointer;
}

.info-popup-content_close span {
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.location-popup {
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: rgba(51, 63, 70, 0.9);
    position: fixed;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.location-popup.active {
    opacity: 1;
    z-index: 999;
}

.location-popup.active .location-popup-content {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.location-popup #map {
    min-height: 100%;
}

.location-popup-content {
    margin: auto;
    width: 50%;
    position: relative;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.location-popup-content__img {
    width: 100%;
    height: 100%;
}

.location-popup-content__img--one {
    background-image: url(../images/modal/two.html);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.location-popup-content__img--two {
    background-image: url(../images/blog-bg.html);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.location-popup-content__img--three {
    background-image: url(../images/slide-three.html);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.location-popup-content__text {
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    background: #fff;
}

.location-popup-content__text-header {
    margin-bottom: 30px;
}

.location-popup-content__text-body {
    margin-bottom: 30px;
}

.location-popup-content__text-body .divide {
    width: 50px;
    height: 1px;
    background: var(--paragraph-color);
    display: inline-block;
}

.location-popup-content__text-is {
    display: block;
    text-transform: capitalize;
    color: #404040;
}

.location-popup-content__title {
    margin-top: 0;
    margin-bottom: 0;
}

.location-popup-content_close {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    background: var(--primary-color);
    display: inline-block;
    border-radius: 30px;
    border: none;
    color: white;
    opacity: 1;
    z-index: 1;
}

.location-popup-content_close:hover {
    cursor: pointer;
}

.location-popup-content_close span {
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.about-style-2 .floating-icon {
    float: left;
    left: 10%;
    margin-top: 50vh;
}

.icon-box {
    border: 2px solid #F5F5F5;
    padding: 43px 50px 28px 50px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: #F5F5F5;
}

.icon-box .sb-icon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.icon-box i {
    -webkit-transition: all .4s linear;
    -o-transition: all .4s linear;
    transition: all .4s linear;
}

.icon-box i:hover {
    color: #404040;
}

.icon-box i:before {
    font-size: 55px;
    margin-left: 5px;
    line-height: 80px;
    color: var(--secondary-color);
}

.icon-box .sb-content h4 {
    color: #404040;
    font-size: 55px;
    letter-spacing: 0;
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 30px;
}

.icon-box .sb-content p {
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0px;
    color: #666;
    font-weight: 400;
    font-family: var(--secondary-font);
    line-height: 25px;
}

.icon-box:hover {
    border-color: transparent;
    background: #fff;
    -webkit-box-shadow: 0px 5px 30px rgba(4, 51, 129, 0.1);
    box-shadow: 0px 5px 30px rgba(4, 51, 129, 0.1);
}

.icon-box:hover .sb-icon {
    color: #404040;
}

.slider.slider-horizontal {
    width: 100%;
}

.slider.slider-horizontal .tooltip.tooltip-main, .slider.slider-vertical .tooltip.tooltip-main {
    opacity: 0.7 !important;
    min-width: 30px;
}

.slider.slider-horizontal .tooltip-inner, .slider.slider-vertical .tooltip-inner {
    font-size: 90%;
    width: auto;
}

.slider-selection {
    background: var(--secondary-color);
}

.slider.slider-horizontal .slider-track {
    height: 5px;
}

.slider-handle {
    border: 3px solid #fff !important;
    width: 14px;
    height: 14px;
    background-color: var(--secondary-color);
}

.tooltip-inner {
    color: var(--secondary-color);
    background-color: #F5F5F5;
}

.tooltip-inner:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid #F5F5F5;
    top: 24px;
}

.image-box-style-01 {
    position: relative;
}

.image-box-style-01 img {
    border-radius: 10px;
    margin-left: 82px;
    margin-top: 82px;
}

.image-box-style-01:before {
    position: absolute;
    width: calc(100% - 82px);
    height: calc(100% - 82px);
    content: '';
    background: var(--secondary-color);
    border-radius: 10px;
    z-index: -1;
}

.image-box-style-01.volunteer-box:before {
    width: 100%;
}

.image-box-style-01.volunteer-box img {
    margin-left: 45px;
    margin-top: 45px;
}

.image-box-style-02 {
    position: relative;
    top: -90px;
}

.image-box-style-02 img {
    padding-left: 50px;
}

.image-box-style-02:before {
    position: absolute;
    width: 400px;
    height: 400px;
    content: '';
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    z-index: -1;
    left: 100px;
    top: 100px;
}

.image-box-style-02:after {
    position: absolute;
    width: 100%;
    height: 70%;
    content: '';
    background: var(--secondary-color);
    z-index: -2;
    left: 0;
    bottom: 0px;
}

.image-box-style-03 {
    position: absolute;
    bottom: 0;
    right: 6%;
    z-index: 9;
}

.image-box-style-03:before {
    position: absolute;
    width: 400px;
    height: 400px;
    content: '';
    background: #113D87;
    border-radius: 50%;
    z-index: -1;
    left: 0px;
    top: 100px;
}

.image-box-style-04 {
    position: relative;
}

.image-box-style-04 .box-date {
    position: absolute;
    top: -50px;
    left: 25%;
    -webkit-box-shadow: 5px 10px 20px #ccc;
    box-shadow: 5px 10px 20px #ccc;
    z-index: 999;
    background: #fff;
    padding: 18px 30px 20px 30px;
    border-radius: 5px;
}

.image-box-style-04 .box-date .box-icon i:before {
    font-size: 40px;
    color: var(--secondary-color);
    margin-right: 20px;
}

.image-box-style-04 .box-date .box-content h4 {
    font-size: 24px;
    letter-spacing: 0;
    color: #404040;
    line-height: 40px;
    font-weight: 400;
    font-family: var(--body-font);
    margin-bottom: 0px;
}

.image-box-style-04 .box-date .box-content h6 {
    font-size: 16px;
    letter-spacing: 0;
    color: #404040;
    font-weight: 400;
    font-family: var(--secondary-font);
    margin-bottom: 5px;
}

.image-box-style-05 {
    position: relative;
}

.image-box-style-05 img {
    border-radius: 10px;
}

.image-box-style-06 {
    position: relative;
}

.image-box-style-06 img {
    border-radius: 10px;
    margin-right: 82px;
    margin-top: 95px;
    width: 90%;
}

.image-box-style-06:before {
    position: absolute;
    width: 85%;
    height: calc(100% - 82px);
    content: '';
    background: url(../images/welcome/welcome-02.jpg);
    border-radius: 10px;
    z-index: -1;
    right: 0px;
    top: -90px;
}

.image-box-style-07 {
    position: absolute;
    bottom: 0;
    right: 6%;
    z-index: 9;
}

.image-box-style-07:before {
    position: absolute;
    width: 400px;
    height: 400px;
    content: '';
    background: #113D87;
    border-radius: 50%;
    z-index: -1;
    left: 0px;
    top: 100px;
}

.header-box-style-01 .description {
    font-family: var(--secondary-font);
    font-size: 16px;
    letter-spacing: 0px;
    color: #616368;
    font-weight: 400;
    line-height: 30px;
    padding-top: 5px;
}

.header-box-style-01 .title {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 10px;
    font-weight: 700;
}

.content-box-style-01 .description {
    font-family: var(--secondary-font);
    font-size: 16px;
    letter-spacing: 0px;
    color: #616368;
    font-weight: 400;
    line-height: 30px;
    padding-top: 5px;
}

.content-box-style-01 .title {
    line-height: 60px;
    margin-bottom: 30px;
    font-weight: 700;
}

.content-box-style-01 .para {
    font-size: 16px;
    font-family: var(--secondary-font);
    line-height: 30px;
    margin-top: 28px;
}

.content-box-style-01 ul {
    padding: 0;
    margin: 0;
}

.content-box-style-01 ul li {
    list-style: none;
    margin: 15px 0px;
}

.content-box-style-01 ul li a {
    font-family: var(--secondary-font);
    letter-spacing: 0;
    color: #666;
    font-weight: 400;
    line-height: 22px;
    font-size: 16px;
}

.content-box-style-01 ul li a i {
    width: 30px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.content-box-style-01 ul li a i:hover {
    color: var(--secondary-color);
}

.content-box-style-01 .name {
    font-size: 24px;
    font-family: var(--body-font);
    font-weight: 400;
    letter-spacing: 0;
    color: #404040;
    line-height: 30px;
    display: block;
}

.content-box-style-01 .address {
    font-family: var(--secondary-font);
    letter-spacing: 0;
    color: #666666;
    font-weight: 400;
    line-height: 30px;
    font-size: 16px;
}

.content-box-style-01 .box-date {
    padding: 15px 40px 15px 0px;
}

.content-box-style-01 .box-date .box-icon i:before {
    font-size: 40px;
    color: var(--secondary-color);
    margin-right: 20px;
}

.content-box-style-01 .box-date .box-content h4 {
    font-size: 24px;
    letter-spacing: 0;
    color: #404040;
    line-height: 40px;
    font-weight: 400;
    font-family: var(--body-font);
    margin-bottom: 0px;
}

.content-box-style-01 .box-date .box-content h6 {
    font-size: 16px;
    letter-spacing: 0;
    color: #404040;
    font-weight: 400;
    font-family: var(--secondary-font);
    margin-bottom: 5px;
}

.causes-right-content {
    padding-left: 50px;
}

.content-box-date {
    margin-bottom: 20px;
}

.content-box-date .box-icon i:before {
    font-size: 65px;
    color: var(--secondary-color);
    margin-right: 20px;
}

.content-box-date .box-content h4 {
    font-size: 55px;
    letter-spacing: 0;
    color: #404040;
    font-weight: 700;
    font-family: var(--body-font);
    margin-bottom: 0px;
}

.content-box-date .box-content h6 {
    font-size: 16px;
    letter-spacing: 0;
    color: #404040;
    font-weight: 400;
    font-family: var(--secondary-font);
    margin-bottom: 5px;
}

/* PROPIOS */
.text-list {
    margin-top: 40px;
}
.text-list__item {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 2px solid #F5F5F5;
    display: flex;
    align-items: center;
}
.text-list__item:last-child {
    border-bottom: 2px solid #F5F5F5;
}
.text-list__content {
    display: flex;
    align-items: flex-start;
}
.text-list__number {
    margin-right: 30px;
    font-size: 1.2em;
    font-weight: 600;
}
.text-list__text {
    font-size: 1em;
    color: #666666;
}
.text-list__text p {
    margin-bottom: 20px;
}
.text-list__text p:last-child {
    margin-bottom: 0;
}
.text-list__image {
    margin-left: 20px;
}
.text-list__image img {
    width: 50px;
}

.multimedia__card {
    display: inline-block;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.multimedia-card {
    display: block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
}
.multimedia-card__image {
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
}
.multimedia-card__image img {
    width: 100%;
}
.multimedia-card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(217, 43, 156, 0.75);
    transition: all 0.3s ease-in-out;
    opacity: 0;
}
.multimedia-card:hover .multimedia-card__content {
    opacity: 1;
}
.multimedia-card__title {
    max-width: 75%;
    font-size: 24px;
    text-align: center;
    color: white;
}
.multimedia-card__date {
    margin-bottom: 0;
    color: white;
}

.interactive-map__container {
    height: calc(100vh - 150px);
}

.interactive-map__container svg {
    max-height: 100%;
}

.interactive-map__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.interactive-map__info {
    padding: 25px 30px;
    background-color: #E0E3F5;
    border-radius: 10px;
}

.interactive-map__info h4 {
    text-align: center;
    font-size: 1rem;
    color: var(--paragraph-color);
}

.interactive-map__info h3 {
    text-align: center;
    margin-bottom: 20px;
}

.multimedia-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

.multimedia-grid__item-1 {
    grid-area: 1 / 1 / 2 / 2;

    @include media-breakpoint-up(md) {
        grid-area: 1 / 1 / 3 / 3;
    }

    @include media-breakpoint-up(lg) {
        grid-area: 1 / 1 / 3 / 3;
    }
}

.multimedia-grid__item-2 {
    grid-area: 2 / 1 / 3 / 2;

    @include media-breakpoint-up(md) {
        grid-area: 3 / 1 / 4 / 2;
    }

    @include media-breakpoint-up(lg) {
        grid-area: 1 / 3 / 2 / 4;
    }
}

.multimedia-grid__item-3 {
    grid-area: 3 / 1 / 4 / 2;

    @include media-breakpoint-up(md) {
        grid-area: 3 / 2 / 4 / 3;
    }

    @include media-breakpoint-up(lg) {
        grid-area: 1 / 4 / 2 / 5;
    }
}

.multimedia-grid__item-4 {
    grid-area: 4 / 1 / 5 / 2;

    @include media-breakpoint-up(md) {
        grid-area: 4 / 1 / 5 / 2;
    }

    @include media-breakpoint-up(lg) {
        grid-area: 2 / 3 / 3 / 4;
    }
}

.multimedia-grid__item-5 {
    grid-area: 5 / 1 / 6 / 2;

    @include media-breakpoint-up(md) {
        grid-area: 4 / 2 / 5 / 3;
    }

    @include media-breakpoint-up(lg) {
        grid-area: 2 / 4 / 3 / 5;
    }
}

.attachments ul li {
    list-style: none;
    margin-bottom: 0.5rem;
}
.attachments ul li:last-child {
    margin-bottom: 0;
}

.attachments ul {
    padding-left: 0;
    margin-bottom: 0;
}
