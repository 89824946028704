// Variables
@import 'variables';

// Animate CSS
@import "~animate.css/source/animate.css";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Magnific Popup
@import "~magnific-popup/dist/magnific-popup.css";

// Slick Carousel
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

// Font Awesome
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "fontawesome/regular";
@import "fontawesome/light";
@import "fontawesome/duotone";
@import "fontawesome/brands";

// Flaticon
@import "flaticon/flaticon";

// Fancyapps UI - Fancybox
@import "~@fancyapps/ui/dist/fancybox.css";

// Custom Styles
@import "styles";
@import "responsive";
