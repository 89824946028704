/*
  Flaticon icon font: Flaticon
  Creation date: 06/10/2019 05:27
  */

@font-face {
    font-family: "Flaticon";
    src: url("#{$fi-font-path}/Flaticon.eot");
    src: url("#{$fi-font-path}/Flaticond41d.eot?#iefix") format("embedded-opentype"),
    url("#{$fi-font-path}/Flaticon.html") format("woff2"),
    url("#{$fi-font-path}/Flaticon.woff") format("woff"),
    url("#{$fi-font-path}/Flaticon.ttf") format("truetype"),
    url("#{$fi-font-path}/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("#{$fi-font-path}/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
    margin-left: 20px;
}

.flaticon-left-arrow-chevron:before { content: "\f100"; }
.flaticon-angle-arrow-pointing-to-right:before { content: "\f101"; }
.flaticon-map:before { content: "\f102"; }
.flaticon-sent-mail:before { content: "\f103"; }
.flaticon-menu-button:before { content: "\f104"; }
.flaticon-earth-globe:before { content: "\f105"; }
.flaticon-money:before { content: "\f106"; }
.flaticon-phone:before { content: "\f107"; }
.flaticon-info:before { content: "\f108"; }
.flaticon-network:before { content: "\f109"; }
.flaticon-close:before { content: "\f10a"; }
.flaticon-open-book:before { content: "\f10b"; }
.flaticon-elections:before { content: "\f10c"; }
.flaticon-plant:before { content: "\f10d"; }
.flaticon-hospital:before { content: "\f10e"; }
.flaticon-money-1:before { content: "\f10f"; }
.flaticon-financial:before { content: "\f110"; }
.flaticon-location:before { content: "\f111"; }
.flaticon-fingerprint:before { content: "\f112"; }
.flaticon-seo:before { content: "\f113"; }
.flaticon-calendar:before { content: "\f114"; }
.flaticon-notes:before { content: "\f115"; }
.flaticon-calendar-1:before { content: "\f116"; }
.flaticon-achievement:before { content: "\f117"; }
.flaticon-fireworks:before { content: "\f118"; }
.flaticon-growth:before { content: "\f119"; }
.flaticon-volunteer:before { content: "\f11a"; }
.flaticon-calendar-2:before { content: "\f11b"; }
.flaticon-growth-1:before { content: "\f11c"; }
.flaticon-euro:before { content: "\f11d"; }
.flaticon-left:before { content: "\f11e"; }
.flaticon-place:before { content: "\f11f"; }
.flaticon-tax:before { content: "\f120"; }
.flaticon-business-and-finance:before { content: "\f121"; }
