@media only screen and (max-width: 1700px) {
    .contact-info-area.margin-top-170, .our-service-area.margin-top-170, .politx-content-area.margin-top-170, .leadership-area,
    .blog-area.margin-top-170, .about-member-count.margin-top-170, .guest-area.margin-top-170, .blog-area.margin-top-165 {
        margin-top: 120px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .content-box-style-01 .box-date {
        padding: 27px 10px 10px 0px;
    }

    .navbar-area .nav-container {
        max-width: 95%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .navbar-area .nav-container .navbar-collapse .navbar-nav li {
        font-size: 16px;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
        right: -9px;
    }

    .navbar-area .nav-container {
        max-width: 100%;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
        margin-left: 5px;
    }

    .navbar-area .nav-container {
        max-width: 95%;
    }

    .nav-style-01 .nav-container {
        padding: 20px 15px !important;
    }

    .navbar-nav li {
        padding-right: 0px !important;
    }

    .nav-right-part ul li.search {
        padding: 6px 15px 6px 0px;
        padding: 10px;
    }

    .news-item-style-03 ul li a {
        font-size: 14px;
        padding: 0px 10px;
    }

    .action-soon-area.padding-bottom-150 {
        padding-bottom: 100px !important;
    }

    .banner-asnavfor-style-01 .slick-prev, .banner-asnavfor-style-01 .slick-next {
        width: 30px;
        height: 30px;
    }

    .banner-asnavfor-style-01 .slick-next {
        top: 84%;
    }

    .banner-asnavfor-style-01 .slick-prev {
        position: absolute;
        top: 88%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .responsive-mobile-menu {
        display: block;
        width: 100%;
        position: relative;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li {
        font-size: 14px;
    }
}

/* landscape query */
@media screen and (max-width: 1199px) and (min-width: 700px) and (orientation: landscape) {
    .margin-lg-top-80 {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 1199px) {
    .content-box-style-01 .box-date .box-content h4 {
        line-height: 25px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .contact-widget .contact_info_list li.single-info-item .details {
        padding-left: 25px;
    }

    .banner-asnavfor-style-01 .slick-prev,
    .banner-asnavfor-style-01 .slick-next {
        display: none !important;
    }

    .banner-asnavfor-style-01:before {
        top: 0;
        height: 100%;
    }

    .politx-content-area .org-img {
        display: none;
    }

    .organization-content {
        padding: 100px 50px 100px 50px;
    }

    .action-soon-content {
        margin-bottom: 60px;
    }

    .details-content-box .cbox-item p {
        line-height: 18px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 991px) {
    .about-member-count.padding-top-135 {
        padding-top: 100px !important;
    }

    .image-box-style-07 {
        display: none;
    }

    .image-box-style-06 {
        margin-bottom: 50px;
    }

    .nav-style-01 .nav-container {
        padding: 15px 0px !important;
    }

    .nav-style-01 .bar1, .nav-style-01 .bar2, .nav-style-01 .bar3 {
        background: #043381;
    }

    .nav-style-01 .responsive-mobile-menu .navbar-toggler {
        left: calc(100% - 295px);
        top: 10px;
        position: absolute;
    }

    .nav-style-01 .nav-right-part ul li.search {
        position: absolute;
        top: 14px;
        left: calc(100% - 255px);
    }

    .nav-style-02 .responsive-mobile-menu .navbar-toggler {
        left: calc(100% - 40px);
        top: 14px;
        position: absolute;
    }

    .nav-style-02 .nav-right-part ul li.search {
        position: absolute;
        top: 34px;
        left: calc(100% - 105px);
    }

    .navbar-area .logo {
        padding-top: 0px !important;
    }

    .publication-card__title {
        font-size: 24px;
        line-height: 1.5;
    }

    .map-img img {
        margin-bottom: 50px;
    }

    .support-area {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .location-area {
        padding-top: 100px;
        padding-bottom: 0px;
    }

    .image-box-style-03 {
        display: none;
    }

    .content-box-style-01 .title {
        line-height: 40px;
        font-size: 30px !important;
    }

    .party-box-area.margin-top-230 {
        margin-top: 180px !important;
    }

    .banner-asnavfor-style-01 .border1, .banner-asnavfor-style-01 .border2, .banner-asnavfor-style-01 .border3, .banner-asnavfor-style-01 .border4 {
        opacity: 0.5;
    }

    .widget ul {
        text-align: left;
    }

    .navbar-collapse {
        background: var(--heading-color);
        padding-left: 35px;
        margin-top: 0px;
        width: 100%;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
        right: 20px;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
        color: #fff;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav {
        margin-bottom: 20px;
    }

    .navbar-area .nav-container .navbar-collapse {
        top: 65px;
    }

    .navbar-area {
        padding-bottom: 0px;
    }

    .banner-asnavfor-style-01 .slick-prev, .banner-asnavfor-style-01 .slick-next {
        width: 30px;
        height: 30px;
    }

    .banner-asnavfor-style-01 .slick-next {
        top: 84%;
    }

    .banner-asnavfor-style-01 .slick-prev {
        position: absolute;
        top: 88%;
    }

    .bar1, .bar2, .bar3 {
        background: #fff;
    }

    .navbar-area .nav-container {
        max-width: 90%;
    }

    .party-box-wrapper {
        padding: 50px 20px;
    }

    .party-box-wrapper .party-box-content h4 {
        font-size: 30px;
        line-height: 45px;
    }

    .margin-lg-top-80 {
        margin-top: 0px;
    }

    .footer-area .copyright-area-inner {
        padding: 20px;
    }

    .navbar-expand-lg .navbar-collapse {
        margin-top: 0px;
    }

    .contact-widget .contact_info_list li.single-info-item .details {
        padding-left: 25px;
    }

    .footer-area .footer-top .widget.widget_nav_menu ul li a {
        font-size: 14px;
    }

    .blog-right-content {
        margin-top: 50px;
    }

    .publication-card__category {
        padding: 3px 10px;
        text-align: center;
        margin-bottom: 1rem;
    }

    .blog-details-style-01 .publication-card__content {
        padding: 80px 30px 30px 30px;
    }

    .blog-details-style-01 .publication-card__date {
        left: 30px;
    }

    .blog-details-style-01 .small-thumb {
        right: 30px;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
        padding: 12px 15px;
    }

    .news-slider-1 {
        min-height: 570px;
        width: min-content;
    }

    .padding-top-175 {
        padding-top: 100px;
    }

    .about-member-count.padding-top-195 {
        padding-top: 70px;
    }

    .icon-box.margin-top-75 {
        margin-top: 0px;
    }

    .padding-bottom-150 {
        padding-bottom: 100px;
    }

    .party-box-area.padding-top-180 {
        padding-top: 100px;
    }

    .home-news-area.padding-top-180 {
        padding-top: 100px;
    }

    .brand-area.padding-bottom-170 {
        padding-bottom: 100px;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav > li:hover > a {
        color: #fff !important;
    }

    .nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
        color: #fff !important;
    }

    .content-box-style-01.margin-top-45 {
        margin-top: 100px;
    }

    .image-box-style-01:before {
        display: none;
    }

    .image-box-style-01.volunteer-box img {
        margin-left: 0px;
        margin-top: 0px;
    }

    .contact-info-area.margin-top-170, .our-service-area.margin-top-170, .politx-content-area.margin-top-170, .margin-top-165, .leadership-area, .faq-area.margin-top-170, .blog-area.margin-top-170, .about-member-count.margin-top-230, .guest-area.margin-top-170, .blog-area.margin-top-165 {
        margin-top: 100px !important;
    }

    .faq-area.padding-top-135 {
        padding-top: 100px;
    }

    .image-box-style-05:before {
        display: none;
    }

    .image-box-style-05 img {
        margin-right: 0px;
        margin-top: 50px;
        width: 100%;
    }

    .party-box-area .btn-wrapper {
        margin-top: 30px;
    }

    .icon-box.margin-top-50 {
        margin-top: 0px !important;
    }

    .error-content h2 {
        font-size: 150px;
        line-height: 150px;
    }

    .error-content h6 {
        font-size: 24px;
        line-height: 30px;
    }

    .error-content a {
        font-size: 24px;
    }

    .causes-right-content {
        padding-left: 0px;
        margin-top: 50px;
    }

    .causes-content-box .cbox-item p {
        line-height: 20px;
        margin-top: 10px;
    }

    .content-box-date .box-content h4 {
        font-size: 30px;
        margin-top: 15px;
    }

    .content-box-style-01 .box-date {
        padding: 5px 0px 10px 0px;
    }

    .choose-content {
        margin-top: 80px;
    }

    .section-title-wh {
        font-size: 30px;
        line-height: 35px;
    }

    .news-item-style-03 {
        margin-bottom: 30px;
    }

    .swiper-slider .news-item-style-03 {
        margin-bottom: 0px !important;
    }

    .news-item-style-03 ul li a {
        padding: 0;
        border-right: 0px;
    }

    .padding-top-145 {
        padding-top: 100px;
    }

    .action-soon-area.padding-bottom-90 {
        padding-bottom: 30px !important;
    }

    .politx-content-area.margin-bottom-120 {
        margin-bottom: 50px;
    }

    .up-event-two.margin-bottom-115 {
        margin-bottom: 45px;
    }

    .party-box-area.padding-top-135 {
        padding-top: 80px;
    }

    .margin-bottom-175 {
        margin-bottom: 100px;
    }

    .margin-top-150 {
        margin-top: 100px;
    }

    .brand-area.padding-top-130 {
        padding-top: 80px;
    }

    .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover i {
        opacity: 0;
        margin-right: -18px;
    }

    .margin-bottom-150 {
        margin-bottom: 100px;
    }

    .padding-top-130 {
        padding-top: 100px;
    }
}

/* Tablet Layout wide: 767px. */
@media only screen and (max-width: 767px) {
    .faq-area .accordion-style-2 .card .card-header a {
        font-size: 24px;
    }

    .up-event-two.padding-bottom-175 {
        padding-bottom: 80px;
    }

    .action-area.padding-top-180 {
        padding-top: 100px;
    }

    .choose-area.margin-top-170 {
        margin-top: 120px !important;
    }

    .error-content-area {
        padding: 30px;
    }

    .logo-wrapper.mobile-logo {
        display: block;
        width: 100%;
    }

    .responsive-mobile-menu {
        display: block;
        width: 100%;
        position: relative;
    }

    .responsive-mobile-menu .navbar-toggler {
        position: absolute;
        left: calc(100% - 130px);
        top: 10px;
    }

    .table-responsive {
        display: block !important;
    }

    .banner-asnavfor-style-01 .slick-prev,
    .banner-asnavfor-style-01 .slick-next {
        display: none !important;
    }

    .padding-left-140 {
        padding-left: 40px;
    }

    .btn-custom-default, .btn-custom-white {
        font-size: 14PX;
        line-height: 33px;
        padding: 6px 20px;
    }

    .navbar-area .nav-container {
        max-width: 100%;
    }

    .image-box-style-01 img {
        margin-left: 10px;
        margin-top: 10px;
    }

    .banner-asnavfor-style-01:before {
        top: 0px;
        height: 100%;
    }

    .banner-asnavfor-style-01 .slick-next {
        top: auto;
        bottom: 100px;
    }

    .banner-asnavfor-style-01 .slick-prev {
        top: auto;
        bottom: 65px;
    }

    .nav-right-part ul li.search {
        top: 30px;
        left: calc(100% - 190px);
    }

    .logo img {
        max-width: 60%;
        margin-left: 10px;
    }

    .navbar-area .logo {
        padding-top: 0px !important;
    }

    .breadcrumb-area .breadcrumb-inner .page-title {
        font-size: 30px;
    }

    .movement-box {
        margin-top: 50px;
    }
}

/* medium tablet layout 599px */
@media only screen and (max-width: 575px) {
    .nav-style-02.navbar-area-fixed .nav-container {
        padding: 14px 0px 18px 0px;
    }

    .nav-style-02 .nav-right-part ul li.search {
        top: 33px;
    }

    .nav-style-02.navbar-area-fixed ul li.search {
        top: 26px;
    }

    .movement-box {
        margin-top: 0px;
    }

    .organization-content {
        padding: 50px 15px;
    }

    .navbar-area .nav-container {
        margin: 0px 0px;
    }

    .navbar-area .logo {
        padding-top: 10px;
    }

    .copyright-sm {
        text-align: left !important;
    }

    .back-to-top {
        bottom: 25px;
    }

    .widget.footer-widget .subscribe-form.subscribe-form-style2 .form-control {
        padding: 15px 20px;
    }

    .widget.footer-widget .subscribe-form.subscribe-form-style2 .btn {
        padding: 15px 20px;
    }

    .contact-form__input, .contact-form__select, .contact-form__textarea {
        line-height: 15px !important;
    }

    .title {
        font-size: 30px;
    }

    .search-popup .search-form {
        min-width: 350px;
    }

    .blog-details-style-01 .publication-card__content .publication-card__meta {
        display: flex;
    }

    .blog-details-style-01 .quote-style-2 {
        width: 100%;
        left: 0;
        margin-left: 0px;
    }

    .nav-style-01 .responsive-mobile-menu .navbar-toggler {
        left: calc(100% - 55px);
        top: 7px;
    }

    .nav-style-01 .nav-right-part ul li.search {
        top: 14px;
        left: calc(100% - 100px);
    }

    .nav-style-01 {
        padding: 0px;
    }

    .banner-area .padding-left-140 {
        padding-left: 20px;
    }

    .up-event-content {
        padding: 0px 15px 50px 15px;
    }

    .news-item-style-03 .title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 375px) {
    .btn-custom-default, .btn-custom-white {
        padding: 5px 18px;
    }

    .search-popup .search-form .form-group .form-control, .search-popup .search-form .submit-btn {
        height: 45px;
    }

    .nav-style-02 .responsive-mobile-menu .navbar-toggler {
        left: calc(100% - 50px);
        top: 10px;
    }

    .nav-style-02 .nav-right-part ul li.search {
        left: calc(100% - 105px);
    }

    .banner-asnavfor-style-01:before {
        height: calc(100% - 90px);
    }

    .nav-right-part ul li {
        margin-right: 10px;
    }

    .search-popup .search-form {
        min-width: 300px;
    }
}

@media only screen and (max-width: 320px) {
    .search-popup .search-form {
        min-width: 265px;
    }

    .nav-right-part ul li.heart {
        width: 40px;
        height: 40px;
        line-height: 45px;
    }

    .nav-right-part ul li {
        margin-right: 5px;
    }

    .responsive-mobile-menu .navbar-toggler {
        left: calc(100% - 95px);
    }

    .nav-right-part ul li.search {
        left: calc(100% - 145px);
    }
}
